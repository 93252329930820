/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { useLocation } from 'react-router-dom';

//import ClientSelect from 'components/ClientSelect';
import Logo from 'components/Logos/Logo';
import Link from 'components/Links/Link';
//import GoogleLogout from 'components/Auth/GoogleLogout';
import LogoutButton from 'components/Auth/Auth0Logout';
import { Nav } from 'react-bootstrap';

import replaceUrlTokens from 'utils/replaceUrlTokens';

const isAdmin = (user) => {
  const profile = user.profile || user;
  const { groups = [] } = profile;
  return groups.includes('systems/admin');
};

function Sidebar({
  color,
  image,
  routes,
  defaultRoute = {},
  onClientChange,
  client,
  accounts,
  user,
}) {
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };
  const isAdminUser = isAdmin(user);
  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: 'url(' + image + ')',
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <span className="simple-text logo-mini mx-1">
            <div className="logo-img">
              <Logo />
            </div>
          </span>
          <span className="simple-text">Stewart 360</span>
        </div>
        <Nav>
          {/*
          <ClientSelect
            client={client}
            accounts={accounts}
            onChange={onClientChange}
          />
          //*/}
          {routes
            .filter(({ name, isAdmin = false }) => {
              if (isAdmin) {
                return isAdminUser && !!name;
              }
              return !!name;
            })
            .map((prop, key) => {
              if (!prop.redirect)
                return (
                  <li
                    className={
                      prop.bottomAction
                        ? 'active active-pro'
                        : activeRoute(prop.layout + prop.path)
                    }
                    key={key}
                  >
                    <Link
                      to={replaceUrlTokens(prop.layout + prop.path, client)}
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </Link>
                  </li>
                );
              return null;
            })}
          <li>
            <LogoutButton className="nav-link" />
          </li>
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
