export const lut = {
  'A+': '4.0',
  A: '4.0',
  'A-': '3.7',
  'B+': '3.3',
  B: '3.0',
  'B-': '2.7',
  'C+': '2.3',
  C: '2.0',
  'C-': '1.7',
  'D+': '1.3',
  D: '1.0',
  E: '0.0',
  F: '0.0',
};

export const normalizeGPA = (val) => {
  return lut[`${val}`.toUpperCase().trim()] || val;
};

export default normalizeGPA;
