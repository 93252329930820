export const toRegularExpression = (re) => {
  if (Array.isArray(re)) {
    return new RegExp(re.join('|'), 'i');
  }
  if (typeof re === 'string') {
    return new RegExp(re, 'i');
  }
  if (re instanceof RegExp) {
    return re;
  }
  throw new Error(
    `Expected Regular Expression but found "${typeof re}" instead.`
  );
};
