import * as d3 from 'd3';

const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);

const valueFrom = (obj) => {
  if (obj.value) {
    return obj.value;
  }
  return obj;
};

export const getValue = (options) => {
  if (isNumeric(options)) {
    return +options;
  }
  const value = valueFrom(options);
  return Array.isArray(value) ? value.length : +value;
};

export const binByTimestamps = (fieldName) =>
  d3.bin().value((d) => new Date(Date.parse(d[fieldName])));

//const colorScheme = d3.schemeAccent;
//const colorScheme = d3.schemeDark2;
//const colorScheme = d3.schemePaired;
//const colorScheme = d3.schemePastel1;
//const colorScheme = d3.schemePastel2;
//const colorScheme = d3.schemeSet1;
//const colorScheme = d3.schemeSet2;
//const colorScheme = d3.schemeSet3;
const colorScheme = d3.schemeTableau10;

const colorSchemeLength = colorScheme.length;
export const colorPicker = (index) => colorScheme[index % colorSchemeLength];
