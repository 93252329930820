import { useState } from 'react';
import BasicPage from 'components/Pages/Basic';
import Error from 'components/Errors/Error';
import Provider from 'data/Provider';
import SchemaForm from 'components/Forms/SchemaForm';
import request from 'data/request';
import { useHistory } from 'react-router';

const props = {
  description: 'Create or manage user.',
  path: '/user/:id',
  isAdmin: true,
};

const schema = {
  definitions: {
    group: {
      title: 'group',
      type: 'string',
      anyOf: [],
    },
    groups: {
      type: 'array',
      uniqueItems: true,
      items: {
        $ref: '#/definitions/group',
      },
    },
  },
  type: 'object',
  oneOf: [
    /*
    {
      title: 'Username/Passsword',
      properties: {
        username: {
          type: 'string',
        },
        password: {
          type: 'string',
        },
        groups: {
          $ref: '#/definitions/groups',
        },
      },
      required: ['username'],
    },*/
    {
      title: 'Integrated Auth',
      properties: {
        email: {
          type: 'string',
        },
        groups: {
          $ref: '#/definitions/groups',
        },
      },
      required: ['email'],
    },
  ],
};

const addSchemaGroup = (schema, identity, groupName) => {
  schema.definitions.group.anyOf.push({
    type: 'string',
    enum: [identity],
    title: groupName,
  });
};

const handleSubmit =
  (history, setError) =>
  ({ formData }) => {
    setError();
    const { _id } = formData;
    const uri = _id
      ? `/api/users/${_id}?format=json`
      : `/api/users?format=json`;
    const method = _id ? 'put' : 'post';

    request({ method, uri, data: formData })
      .then(() => {
        history.push('/admin/users');
      })
      .catch((e) => {
        if (e.response) {
          console.error(e.response);
          if (e.response.data) {
            return setError(e.response.data);
          }
        }
        console.error(e);
        setError(e);
      });
  };

const EditForm = (props) => {
  const history = useHistory();
  const [error, setError] = useState();
  const { match } = props;
  const id = match.params.id;
  const provide =
    id === 'new'
      ? {
          accounts: `/api/accounts?format=json`,
        }
      : {
          accounts: `/api/accounts?format=json`,
          user: `/api/user/${id}?format=json`,
        };

  return (
    <>
      <Error error={error} />
      <Provider
        {...props}
        provide={provide}
        view={({ accounts, user = {} }) => {
          const uischema = { ...schema };
          uischema.definitions.group.anyOf = [];
          addSchemaGroup(uischema, 'systems/admin', 'Stewart Admin');
          accounts.forEach((props) => {
            const { accountId, name } = props;
            addSchemaGroup(uischema, `client/${accountId}`, name);
          });
          return (
            <SchemaForm
              schema={uischema}
              formData={user}
              onSubmit={handleSubmit(history, setError)}
            />
          );
        }}
      />
    </>
  );
};

const Component = (props) => (
  <BasicPage title="User" icon="nc-single-02">
    <EditForm {...props} />
  </BasicPage>
);

const User = {
  ...props,
  Component,
};

export default User;
