import gpa from 'utils/gpa';

export const commonMapper = {
  type: 'object',
  rules: {
    gpa: {
      values: gpa,
    },
    email: {
      fields: ['email', 'emailaddress'],
    },
    zip: {
      fields: ['zip', 'zipcode', 'postalcode', 'otherzippostalcode'],
    },
    address: {
      fields: ['address', 'address1', 'otherstreet', 'streetaddress'],
    },
    zipplusfour: {
      fields: ['zip4', 'zipplus4', 'plus4', 'zipplusfour'],
    },
    dob: {
      fields: ['birthdate', 'dateofbirth', 'dob'],
      values(dob) {
        if (!dob) {
          return dob;
        }
        if (isNaN(Date.parse(dob))) {
          if (dob.length === 8) {
            const parts = dob.match(/.{1,2}/g);
            if (['19', '20'].indexOf(parts[0]) > -1) {
              // Assume yyyymmdd
              return `${parts[2]}/${parts[3]}/${parts[0]}${parts[1]}`;
            }
            // Assume mmddyyyy
            return `${parts[0]}/${parts[1]}/${parts[2]}${parts[3]}`;
          }
          return dob;
        }
        return dob;
      },
    },
    county: {
      fields: ['county', 'fipscounty'],
    },
    state: {
      fields: ['state', 'stateprovince', 'stateabbreviation'],
    },
  },
};

export default commonMapper;
