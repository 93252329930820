import { useHistory } from 'react-router-dom';

import Button from 'components/Buttons/Button';

const LinkButton = ({ to = '/', children, ...props }) => {
  const history = useHistory();
  const redirect = () => history.push(to);

  return (
    <Button {...props} onClick={redirect}>
      {children}
    </Button>
  );
};

export default LinkButton;
