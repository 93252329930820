import { useState, useEffect } from 'react';
import mapValues from 'async/mapLimit';
import getUriResource from 'data/getUriResource';
import { useAuth0 } from '@auth0/auth0-react';

import Loading from 'components/Loader/Loading';
import Error from 'components/Errors/Error';
import Button from 'components/Buttons/Button';

const makeFetchOptions = (...args) => {
  const fetchOptions = args.reduce((opts, arg) => {
    if (typeof arg === 'string') {
      return { ...opts, url: arg };
    }
    return { ...opts, ...arg };
  }, {});
  //console.log({ args, fetchOptions });
  return fetchOptions;
};

const fetchResource = async (accessToken, resource, updateFunction) => {
  const type = typeof resource;
  if (type === 'string') {
    return await getUriResource(
      makeFetchOptions(resource, { accessToken }),
      updateFunction
    );
  }
  if (type === 'function') {
    return await resource({ accessToken, updateFunction });
  }
  throw new Error(`Unknown resource type "${type}"`);
};

const LoadingViewItem = ({ name, status }) => (
  <p>
    <strong key={`${name}_dt`}>{name}: </strong>
    <span>{status}</span>
  </p>
);

const Provider = ({
  loadingView: LoadingView,
  view: View,
  provide,
  ...props
}) => {
  const [data, setData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState({});
  const [error, setError] = useState();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      setLoaded(false);
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIANCE, // Value in Identifier field for the API being called.
          scope: 'profile email read:email', // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
        },
      });

      try {
        const keys = Object.keys(provide);
        const values = Object.entries(provide);
        const loading = keys.reduce(
          (loading, key) => ({ ...loading, [key]: 'Waiting' }),
          {}
        );
        const updateLoading = (key, value) => {
          console.info(key, value);
          setLoading({ ...loading, [key]: value });
          loading[key] = value;
        };

        const mappedValues = await mapValues(
          values,
          3,
          ([key, resource], next) => {
            const fetch = () => {
              updateLoading(key, 'Fetching');
              fetchResource(accessToken, resource, (update) =>
                updateLoading(key, update)
              )
                .then((value) => {
                  updateLoading(key, 'Loaded');
                  return next(null, value);
                })
                .catch((err) => {
                  err.message = err.message.replace(
                    /Request failed/,
                    `Request for "${key}" failed `
                  );
                  console.error(err);
                  //next(err);
                  updateLoading(
                    key,
                    <div>
                      <Error error={err} />
                      <Button onClick={() => fetch()}>Retry</Button>
                    </div>
                  );
                });
            };
            fetch();
          }
        );

        const map = mappedValues.reduce(
          (map, value, index) => ({
            ...map,
            [keys[index]]: value,
          }),
          {}
        );
        setData(map);
        setLoaded(true);
      } catch (e) {
        setError(e);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, Object.values(props));

  if (error) {
    return <Error error={error} />;
  }

  if (!loaded) {
    return LoadingView ? (
      <LoadingView data={loading} />
    ) : (
      <Loading>
        {Object.entries(loading).map(([key, status]) => (
          <LoadingViewItem key={key} name={key} status={status} />
        ))}
      </Loading>
    );
  }

  return <View {...props} {...data} />;
};

export default Provider;
