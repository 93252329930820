import DataFormPage from 'components/Pages/DataForm';
import { Group, Label, Input } from 'components/Forms/Form';
import MapEditor from 'components/Editors/Map';
import ColumnsEditor from 'components/Editors/Columns';
import Tabs from 'components/Tabs/Tabs';
import Tab from 'components/Tabs/Tab';
//import ErrorPanel from 'components/Errors/Error';
import Provider from 'data/Provider';
import { strToArray } from 'utils/strToArray';

const props = {
  description: 'Configures a mapper.',
  path: '/system/configure/:id',
  isAdmin: true,
};

const reIsMapField = /^map([A-Z][A-Za-z0-9]+)+/;

const lowerFirst = (s) => s[0].toLowerCase() + s.slice(1);

const handleSubmit = (formData) => {
  const {
    name,
    pattern,
    outputColumns: outputColumnsSource,
    fileHeaders: fileHeadersSource,
  } = formData;
  const map = Object.entries(formData).reduce((map, [fieldName, value]) => {
    const match = reIsMapField.exec(fieldName);
    if (match) {
      const mapFieldName = lowerFirst(match[1]);
      return { ...map, [mapFieldName]: value };
    }
    return map;
  }, {});
  const mapper = {
    name,
    pattern,
    map,
    outputColumns: strToArray(outputColumnsSource),
    fileHeaders: strToArray(fileHeadersSource),
  };
  //console.log('map', map);
  //console.log('Submitted final', mapper);
};

const Component = ({ client, match }) => {
  return (
    <Provider
      client={client}
      provide={{
        mappers: '/api/mappers',
      }}
      view={({ mappers }) => (
        <DataFormPage {...props} debug={true} onSubmit={handleSubmit}>
          <Group className="mb-3">
            <Label>Name</Label>
            <Input name="name" />
          </Group>
          <Group className="mb-3">
            <Label>Filename Pattern</Label>
            <Input name="pattern" />
          </Group>
          <Group className="mb-3">
            <Tabs defaultActiveKey="map">
              <Tab eventKey="map" title="Field Map">
                <MapEditor name="map" />
              </Tab>
              <Tab eventKey="columns" title="Output Columns">
                <Label>
                  In the case where the mapped columns don't line up with the
                  standard format or when there are columns you want to exclude
                  from the output, use this form to define what columns will be
                  output.
                  <br />
                  <strong>NOTE: </strong> Only the columns defined will be
                  output.
                </Label>
                <ColumnsEditor name="outputColumns" />
              </Tab>
              <Tab eventKey="dataFileColumns" title="Data File Headers">
                <Label>
                  In the case where a data file format doesn't contain headers,
                  use this section to define the headers for the file.
                </Label>
                <ColumnsEditor name="fileHeaders" />
              </Tab>
            </Tabs>
          </Group>
        </DataFormPage>
      )}
    />
  );
};

const Mapper = {
  ...props,
  Component,
};

export default Mapper;
