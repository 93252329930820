import mapValuesSeries from 'async/mapValuesSeries';
import Reform from 'utils/Reform';
//import valueFrom from 'utils/valueFrom';
import defaultedValue from 'utils/defaultedValue';

export const reNotAlphaNum = /[^a-z0-9]/gi;

const valueFrom = (values, lookup, source, row) => {
  if (typeof values === 'function') {
    return values(lookup, source, row);
  }
  return values[lookup] || lookup;
};

export const makeClean = (src) => {
  return ((src || '') + '').toLowerCase().replace(reNotAlphaNum, '');
};

export const cleanKeys = (o) => {
  return Object.keys(o).reduce((res, key) => {
    return Object.assign(res, { [makeClean(key)]: o[key] });
  }, {});
};

export const getFieldValue = (fields, vals) => {
  return fields.reduce((val, fieldName) => {
    if (!val && typeof vals[fieldName] !== 'undefined') {
      return vals[fieldName];
    }
    return val;
  }, undefined);
};

export const getMappers = (map) => {
  try {
    const mapKeys = Object.keys(map);
    const mappers = mapKeys.map((key) => {
      if (typeof map[key] !== 'object') {
        const value = map[key];
        if (typeof value === 'string') {
          const r = new Reform(value);
          return (row) => {
            return Object.assign(row, { [key]: r.reform(row) });
          };
        }
        return (row) => {
          return Object.assign(row, { [key]: value });
        };
      }
      const fieldNames = map[key].fields;
      const fieldName = makeClean(map[key].field || key);
      const values = map[key].values;
      const mappingDefaultValue = map[key].default;

      return (row, vals) => {
        const fieldValue = getFieldValue(fieldNames || [fieldName], vals);
        if (values) {
          const value = defaultedValue(
            valueFrom(values, fieldValue, vals, row),
            mappingDefaultValue
          );
          return Object.assign(row, { [key]: value });
        }
        return Object.assign(row, { [key]: fieldValue });
      };
    });
    return mappers;
  } catch (e) {
    console.error('Error getting mappers', map, e);
    throw e;
  }
};

//*
export const lowerMapRow =
  (mappers) =>
  (src, row = {}) => {
    const mapRow = (src) => {
      const toRow = mappers.reduce((row, mapper) => {
        return mapper(row, src);
      }, row);
      return toRow;
    };
    const low = Object.keys(src).reduce((row, key) => {
      const fieldName = key.toLowerCase().replace(reNotAlphaNum, '');
      return Object.assign(row, { [fieldName]: src[key] });
    }, {});
    return mapRow(low);
  };
//*/

const ObjectMapper = ({ rules, data, onProgress = () => {} }) => {
  const mappers = getMappers(rules);
  const mapRow = lowerMapRow(mappers);

  const mapItem = ({ item, index, count }) => {
    return mapRow(item);
  };

  const doProgress = ({ index, count, item }) =>
    onProgress && onProgress({ index, count, item });

  return new Promise((resolve, reject) => {
    if (!Array.isArray(data)) {
      return resolve(mapItem({ item: data, index: 0, count: 1 }));
    }
    const count = data.length;

    mapValuesSeries(
      data,
      (item, index, next) => {
        const waitForIt = index % 50 === 0;
        doProgress({ index, count, item });

        if (waitForIt) {
          return setImmediate(() =>
            next(null, mapItem({ item, index, count }))
          );
        }
        return next(null, mapItem({ item, index, count }));
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }

        const recs = [].concat(
          ...Object.values(data) //.filter((l) => l && l.length)
        );
        return resolve(recs);
      }
    );
  });
};

export default ObjectMapper;
