import { useState } from 'react';
import TableSearchBar from 'components/Tables/TableSearchBar';
import TablePaginator from 'components/Tables/TablePaginator';
import {
  getRecordMembers,
  calculateColumns,
  makeColumnHeaderText,
} from 'components/Tables/helpers';

import * as stringify from 'csv-stringify';

import downloadFile from 'utils/downloadFile';

import { v4 as uuidv4 } from 'uuid';

const newId = () => uuidv4();

const flattenObjectValues = (o) => {
  if (typeof o === 'object' && !o) {
    return '';
  }
  return Object.values(o).map(flattenValues);
};

const flattenArrayValues = (a) => {
  return a.map(flattenValues);
};

const flattenValues = (o) => {
  if (o instanceof Date) {
    return o.toISOString();
  }
  if (typeof o === 'object') {
    return [
      ...(Array.isArray(o) ? flattenArrayValues(o) : flattenObjectValues(o)),
    ]
      .flat(Infinity)
      .filter((v) => !!v);
  }
  return o;
};

const stringValueOf = (v) => {
  const type = typeof v;
  if (type === 'undefined') {
    return '';
  }
  if (Array.isArray(v)) {
    return `${flattenValues(v).join(',')}`;
  }
  if (v instanceof Date) {
    return v.toISOString();
  }
  if (type === 'object') {
    return `${flattenValues(v).join(',')}`;
  }
  if (type === 'string') {
    return v;
  }
  return `${v}`;
};

const removeFakeIds = (data) => data.map(({ __id__, ...row }) => row);

const TableProvider = ({
  children: Children,
  keyField,
  data: rawData,
  columns,
  filename = 'download.csv',
  onExport,
  ...props
}) => {
  if (columns) {
    columns = calculateColumns(columns);
  }
  if (!columns) {
    const fields = getRecordMembers(rawData);
    columns = fields.map((fn) => ({
      dataField: fn,
      text: makeColumnHeaderText(fn),
    }));
  }

  const [dataWithIds] = useState(
    rawData.map((row) => ({ __id__: newId(), ...row }))
  );
  const [data, setData] = useState(dataWithIds);

  const [selected, setSelected] = useState([]);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const exportData = () => {
    const downloadData = removeFakeIds(data);
    const selectedData = removeFakeIds(
      data.filter(({ __id__ }) => selected.includes(__id__))
    );
    if (onExport) {
      return onExport({
        data: downloadData,
        selected: selectedData,
        columns,
        filename,
      });
    }
    stringify(
      selectedData.length > 0 ? selectedData : downloadData,
      {
        columns: columns.map(({ dataField: key, text: header }) => ({
          key,
          header,
        })),
        header: true,
      },
      (err, csv) => {
        if (err) {
          return alert(err);
        }
        downloadFile(filename, csv);
      }
    );
  };

  const filterData = (filterText) => {
    const reIsMatch = new RegExp(filterText, 'i');
    const filteredData = dataWithIds
      .map((o) => ({
        match:
          Object.values(o).filter((v) => !!reIsMatch.exec(stringValueOf(v)))
            .length > 0,
        data: o,
      }))
      .filter(({ match }) => match)
      .map(({ data }) => data);
    setPage(1);
    setData(filteredData);
  };

  const deselectRecordById = (id) =>
    setSelected(selected.filter((sid) => sid !== id));
  const selectRecordById = (id) =>
    setSelected(selected.filter((sid) => sid !== id).concat(id));

  const selectAll = () => setSelected(data.map(({ __id__ }) => __id__));
  const deselectAll = () => setSelected([]);

  const offset = (page - 1) * pageSize;
  const viewData = data.slice(offset, offset + pageSize);

  const paginator = (
    <TablePaginator
      data={data}
      pageSize={pageSize}
      setPageSize={setPageSize}
      page={page}
      setPage={setPage}
    />
  );

  return (
    <>
      {paginator}
      <TableSearchBar onSearch={filterData} onExportClick={exportData} />
      <Children
        {...props}
        columns={columns}
        data={viewData}
        selected={selected}
        onDeselectRecord={deselectRecordById}
        onSelectRecord={selectRecordById}
        onDeselectAll={deselectAll}
        onSelectAll={selectAll}
        allSelected={selected.length === data.length}
      />
      {paginator}
    </>
  );
};

export default TableProvider;
