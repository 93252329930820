import React from 'react';
//import { useState } from 'react';
import AdminLayout from 'layouts/Admin.js';
import Provider from 'data/Provider';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthPanel from 'components/Auth/Auth0AuthPanel';
//import { getToken } from 'data/auth';
//import { GoogleOAuthProvider } from '@react-oauth/google';
import { Auth0Provider } from '@auth0/auth0-react';

const AppView = ({ accounts, user }) => (
  <BrowserRouter>
    <Switch>
      <Route
        path="/admin"
        render={(props) => (
          <AdminLayout {...props} accounts={accounts} user={user} />
        )}
      />
      <Redirect from="/" to="/admin" />
    </Switch>
  </BrowserRouter>
);

const App = () => {
  const auth0ProviderConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    //onRedirectCallback,
    authorizationParams: {
      redirect_uri: window.location.origin,
      ...(process.env.REACT_APP_AUTH0_AUDIANCE
        ? { audience: process.env.REACT_APP_AUTH0_AUDIANCE }
        : null),
    },
  };

  const providerConfig = {
    accounts: `/api/accounts`,
    user: `/api/users/me`,
  };

  return (
    <Auth0Provider {...auth0ProviderConfig}>
      <AuthPanel autoLogin={true}>
        <Provider provide={providerConfig} view={AppView} />
      </AuthPanel>
    </Auth0Provider>
  );
};

export default App;
