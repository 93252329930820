import { Card as BSCard } from 'react-bootstrap';

const Header = ({ title, description }) => {
  if (!title) {
    return <></>;
  }
  const descriptionBlock = description ? (
    <div className="card-category">{description}</div>
  ) : (
    <></>
  );
  return (
    <BSCard.Header>
      <BSCard.Title as="h4">{title}</BSCard.Title>
      {descriptionBlock}
    </BSCard.Header>
  );
};

export default Header;
