import DataFormPage from 'components/Pages/DataForm';
import MapEditor from 'components/Editors/Map';
import ColumnsEditor from 'components/Editors/Columns';
import Tabs from 'components/Tabs/Tabs';
import Tab from 'components/Tabs/Tab';
import loadMapFromString from 'utils/loadMapFromString';
import { asHeaderKey } from 'components/Tables/helpers';
import strToArray from 'utils/strToArray';
import request from 'data/request';
import Provider from 'data/Provider';

const props = {
  caption: 'Configure Client',
  description: 'Configures the current client settings.',
  icon: 'nc-settings-gear-64',
  path: '/account/:accountId/client/configure',
  isAdmin: true,
};

const handleSubmit =
  async (client) =>
  (formData, { setDebugData, setError, setStatus }) => {
    const {
      map: mapSource,
      columns: columnsSource,
      exportMap: exportMapSource,
      exportColumns: exportColumnsSource,
      debug,
    } = formData;
    const map = loadMapFromString(mapSource);
    const columns = strToArray(columnsSource).map((col) => {
      const { header, key } = asHeaderKey(col);
      return { header, key };
    });
    const exportMap = loadMapFromString(exportMapSource);
    const exportColumns = strToArray(exportColumnsSource).map((col) => {
      const { header, key } = asHeaderKey(col);
      return { header, key };
    });
    const newClient = { ...client, map, columns, exportMap, exportColumns };
    const url = `/api/accounts/${client.accountId}`;
    if (debug) {
      return console.log(newClient);
      /*
    return setDebugData(newClient);
    */
    }
    return request({ url, method: 'post', data: newClient });
  };

const Component = ({ client, match }) => (
  <Provider
    client={client}
    provide={{
      client: `/api/accounts/${client.accountId}`,
    }}
    view={({ client }) => {
      //console.log('new client', client);
      return (
        <DataFormPage {...props} onSubmit={handleSubmit(client)}>
          <Tabs defaultActiveKey="mergeMap">
            <Tab eventKey="mergeMap" title="Merge Map">
              <MapEditor map={client.map} name="map" />
            </Tab>
            <Tab eventKey="mergeColumns" title="Merge Columns">
              <ColumnsEditor columns={client.columns} name="columns" />
            </Tab>
            <Tab eventKey="exportMap" title="Export Map">
              <MapEditor map={client.exportMap} name="exportMap" />
            </Tab>
            <Tab eventKey="exportColumns" title="Export Columns">
              <ColumnsEditor
                columns={client.exportColumns}
                name="exportColumns"
              />
            </Tab>
          </Tabs>
        </DataFormPage>
      );
    }}
  />
);

/*
const Component = ({ client, match }) => (
  <DataFormPage {...props} onSubmit={handleSubmit(client)}>
    <Group className="mb-3">
      <Label>Merge Map</Label>
      <MapEditor map={client.map} name="map" />
    </Group>
    <Group className="mb-3">
      <Label>Merge Columns</Label>
      <ColumnsEditor columns={client.columns} name="columns" />
    </Group>
    <Group className="mb-3">
      <Label>Export Map</Label>
      <MapEditor map={client.exportMap} name="export-map" />
    </Group>
    <Group className="mb-3">
      <Label>Export Columns</Label>
      <ColumnsEditor columns={client.exportColumns} name="export-columns" />
    </Group>
  </DataFormPage>
);
//*/

const ConfigureClient = {
  ...props,
  Component,
};

export default ConfigureClient;
