import loadObjectFromString, {
  strToArray as objStrToArray
} from 'utils/loadObjectFromString';

export const strToArray = objStrToArray;

export const strOrObjToArray = (str, { noObjectLoad = false } = {}) => {
  if (noObjectLoad === false) {
    try {
      return loadObjectFromString(str);
    } catch (e) {
      return objStrToArray(str);
    }
  }
  return objStrToArray(str);
};

export default strOrObjToArray;
