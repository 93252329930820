import BasicPage from 'components/Pages/Basic';
import DeleteButton from 'components/Buttons/Delete';
import Provider from 'data/Provider';
import Table from 'components/Tables/Table';
import LinkButton from 'components/Buttons/Link';
import { useHistory } from 'react-router-dom';
import request from 'data/request';

const props = {
  caption: 'Configure System',
  description: 'Configures the global system settings.',
  icon: 'nc-settings-gear-64',
  path: '/system/configure',
  isAdmin: true,
  exact: true
};

/*
{
  name: 'Some Name',
  pattern: 'some regular expression',
  mapper: {
    type: '',
    ...
  },
  exportColumns: [],
  fileColumns: []
}
*/

/*
const Component = ({ client, match }) => {
  return (
    <Provider
      client={client}
      provide={{
        mappers: '/api/mappers'
      }}
      view={({ mappers }) => (
        <DataFormPage {...props} debug={true} onSubmit={handleSubmit}>
          <Group className="mb-3">
            <Label>Select Rule</Label>
            <Select name="name">
              {mappers.map(({ name }) => (
                <option key={name}>{name}</option>
              ))}
            </Select>
          </Group>
          <Group className="mb-3">
            <Label>Filename Pattern</Label>
            <Control type="text" name="pattern" />
          </Group>
          <Group className="mb-3">
            <Tabs defaultActiveKey="map">
              <Tab eventKey="map" title="Field Map">
                <MapEditor name="map" />
              </Tab>
              <Tab eventKey="columns" title="Export Columns">
                <ColumnsEditor name="columns" />
              </Tab>
              <Tab eventKey="dataFileColumns" title="Data File Columns">
                <ColumnsEditor name="dataFileColumns" />
              </Tab>
            </Tabs>
          </Group>
          <Group className="mb-3">
            <Button>New</Button>
            <DeleteButton />
          </Group>
        </DataFormPage>
      )}
    />
  );
};
*/

const deleteMap = (map, history) => {
  const uri = `/api/mappers/${map._id}?format=json`;
  request({ method: 'delete', uri })
    .then(() => {
      history.push('/admin/system/configure');
    })
    .catch((e) => console.error(e));
};

const Component = ({ client, match }) => {
  const history = useHistory();

  const columns = [
    { key: 'name' },
    { key: 'pattern' },
    {
      key: 'actions',
      isDummyField: true,
      formatter(field, { _id }) {
        return (
          <LinkButton to={`/admin/system/configure/${_id}`}>View</LinkButton>
        );
      }
    }
  ];
  return (
    <Provider
      client={client}
      provide={{
        mappers: '/api/mappers'
      }}
      view={({ mappers }) => (
        <>
          <BasicPage {...props}>
            <Table
              data={mappers
                .map((map) => {
                  const { name, pattern, _id } = map;
                  return {
                    name,
                    pattern,
                    actions: (
                      <>
                        <LinkButton to={`/admin/system/configure/${_id}`}>
                          Edit
                        </LinkButton>
                        <DeleteButton
                          title="Delete"
                          onDelete={() => deleteMap(map, history)}
                        >
                          Are you sure you want to delete {name}?
                        </DeleteButton>
                      </>
                    )
                  };
                })
                .sort((a, b) => a.name.localeCompare(b.name))}
              columns={columns}
            />
            <LinkButton to="/admin/system/configure/new">New Mapper</LinkButton>
          </BasicPage>
        </>
      )}
    />
  );
};

const ConfigureClient = {
  ...props,
  Component
};

export default ConfigureClient;
