const lookupTable = [
  {
    "stateLu": "ALABAMA",
    "state": "Alabama",
    "abbreviation": "AL"
  },
  {
    "stateLu": "ALASKA",
    "state": "Alaska",
    "abbreviation": "AK"
  },
  {
    "stateLu": "ARIZONA",
    "state": "Arizona",
    "abbreviation": "AZ"
  },
  {
    "stateLu": "ARKANSAS",
    "state": "Arkansas",
    "abbreviation": "AR"
  },
  {
    "stateLu": "CALIFORNIA",
    "state": "California",
    "abbreviation": "CA"
  },
  {
    "stateLu": "COLORADO",
    "state": "Colorado",
    "abbreviation": "CO"
  },
  {
    "stateLu": "CONNECTICUT",
    "state": "Connecticut",
    "abbreviation": "CT"
  },
  {
    "stateLu": "DELAWARE",
    "state": "Delaware",
    "abbreviation": "DE"
  },
  {
    "stateLu": "FLORIDA",
    "state": "Florida",
    "abbreviation": "FL"
  },
  {
    "stateLu": "GEORGIA",
    "state": "Georgia",
    "abbreviation": "GA"
  },
  {
    "stateLu": "HAWAII",
    "state": "Hawaii",
    "abbreviation": "HI"
  },
  {
    "stateLu": "IDAHO",
    "state": "Idaho",
    "abbreviation": "ID"
  },
  {
    "stateLu": "ILLINOIS",
    "state": "Illinois",
    "abbreviation": "IL"
  },
  {
    "stateLu": "INDIANA",
    "state": "Indiana",
    "abbreviation": "IN"
  },
  {
    "stateLu": "IOWA",
    "state": "Iowa",
    "abbreviation": "IA"
  },
  {
    "stateLu": "KANSAS",
    "state": "Kansas",
    "abbreviation": "KS"
  },
  {
    "stateLu": "KENTUCKY",
    "state": "Kentucky",
    "abbreviation": "KY"
  },
  {
    "stateLu": "LOUISIANA",
    "state": "Louisiana",
    "abbreviation": "LA"
  },
  {
    "stateLu": "MAINE",
    "state": "Maine",
    "abbreviation": "ME"
  },
  {
    "stateLu": "MARYLAND",
    "state": "Maryland",
    "abbreviation": "MD"
  },
  {
    "stateLu": "MASSACHUSETTS",
    "state": "Massachusetts",
    "abbreviation": "MA"
  },
  {
    "stateLu": "MICHIGAN",
    "state": "Michigan",
    "abbreviation": "MI"
  },
  {
    "stateLu": "MINNESOTA",
    "state": "Minnesota",
    "abbreviation": "MN"
  },
  {
    "stateLu": "MISSISSIPPI",
    "state": "Mississippi",
    "abbreviation": "MS"
  },
  {
    "stateLu": "MISSOURI",
    "state": "Missouri",
    "abbreviation": "MO"
  },
  {
    "stateLu": "MONTANA",
    "state": "Montana",
    "abbreviation": "MT"
  },
  {
    "stateLu": "NEBRASKA",
    "state": "Nebraska",
    "abbreviation": "NE"
  },
  {
    "stateLu": "NEVADA",
    "state": "Nevada",
    "abbreviation": "NV"
  },
  {
    "stateLu": "NEWHAMPSHIRE",
    "state": "New Hampshire",
    "abbreviation": "NH"
  },
  {
    "stateLu": "NEWJERSEY",
    "state": "New Jersey",
    "abbreviation": "NJ"
  },
  {
    "stateLu": "NEWMEXICO",
    "state": "New Mexico",
    "abbreviation": "NM"
  },
  {
    "stateLu": "NEWYORK",
    "state": "New York",
    "abbreviation": "NY"
  },
  {
    "stateLu": "NORTHCAROLINA",
    "state": "North Carolina",
    "abbreviation": "NC"
  },
  {
    "stateLu": "NORTHDAKOTA",
    "state": "North Dakota",
    "abbreviation": "ND"
  },
  {
    "stateLu": "OHIO",
    "state": "Ohio",
    "abbreviation": "OH"
  },
  {
    "stateLu": "OKLAHOMA",
    "state": "Oklahoma",
    "abbreviation": "OK"
  },
  {
    "stateLu": "OREGON",
    "state": "Oregon",
    "abbreviation": "OR"
  },
  {
    "stateLu": "PENNSYLVANIA",
    "state": "Pennsylvania",
    "abbreviation": "PA"
  },
  {
    "stateLu": "RHODEISLAND",
    "state": "Rhode Island",
    "abbreviation": "RI"
  },
  {
    "stateLu": "SOUTHCAROLINA",
    "state": "South Carolina",
    "abbreviation": "SC"
  },
  {
    "stateLu": "SOUTHDAKOTA",
    "state": "South Dakota",
    "abbreviation": "SD"
  },
  {
    "stateLu": "TENNESSEE",
    "state": "Tennessee",
    "abbreviation": "TN"
  },
  {
    "stateLu": "TEXAS",
    "state": "Texas",
    "abbreviation": "TX"
  },
  {
    "stateLu": "UTAH",
    "state": "Utah",
    "abbreviation": "UT"
  },
  {
    "stateLu": "VERMONT",
    "state": "Vermont",
    "abbreviation": "VT"
  },
  {
    "stateLu": "VIRGINIA",
    "state": "Virginia",
    "abbreviation": "VA"
  },
  {
    "stateLu": "WASHINGTON",
    "state": "Washington",
    "abbreviation": "WA"
  },
  {
    "stateLu": "WESTVIRGINIA",
    "state": "West Virginia",
    "abbreviation": "WV"
  },
  {
    "stateLu": "WISCONSIN",
    "state": "Wisconsin",
    "abbreviation": "WI"
  },
  {
    "stateLu": "WYOMING",
    "state": "Wyoming",
    "abbreviation": "WY"
  },
  {
    "stateLu": "AMERICANSAMOA",
    "state": "American Samoa",
    "abbreviation": "AS"
  },
  {
    "stateLu": "DISTRICTOFCOLUMBIA",
    "state": "District of Columbia",
    "abbreviation": "DC"
  },
  {
    "stateLu": "FEDERATEDSTATESOFMICRONESIA",
    "state": "Federated States of Micronesia",
    "abbreviation": "FM"
  },
  {
    "stateLu": "GUAM",
    "state": "Guam",
    "abbreviation": "GU"
  },
  {
    "stateLu": "MARSHALLISLANDS",
    "state": "Marshall Islands",
    "abbreviation": "MH"
  },
  {
    "stateLu": "NORTHERNMARIANAISLANDS",
    "state": "Northern Mariana Islands",
    "abbreviation": "MP"
  },
  {
    "stateLu": "PALAU",
    "state": "Palau",
    "abbreviation": "PW"
  },
  {
    "stateLu": "PUERTORICO",
    "state": "Puerto Rico",
    "abbreviation": "PR"
  },
  {
    "stateLu": "VIRGINISLANDS",
    "state": "Virgin Islands",
    "abbreviation": "VI"
  },
  {
    "stateLu": "ARMEDFORCESAFRICA",
    "state": "Armed Forces Africa",
    "abbreviation": "AE"
  },
  {
    "stateLu": "ARMEDFORCESAMERICAS",
    "state": "Armed Forces Americas",
    "abbreviation": "AA"
  },
  {
    "stateLu": "ARMEDFORCESCANADA",
    "state": "Armed Forces Canada",
    "abbreviation": "AE"
  },
  {
    "stateLu": "ARMEDFORCESEUROPE",
    "state": "Armed Forces Europe",
    "abbreviation": "AE"
  },
  {
    "stateLu": "ARMEDFORCESMIDDLEEAST",
    "state": "Armed Forces Middle East",
    "abbreviation": "AE"
  },
  {
    "stateLu": "ARMEDFORCESPACIFIC",
    "state": "Armed Forces Pacific",
    "abbreviation": "AP"
  },
  {
    "stateLu": "",
    "state": ""
  }
];

export const getState = (abbreviation = '') => {
  abbreviation = abbreviation.toUpperCase().replace(/[^A-Z]/g, '');
  const recs = lookupTable.filter(r => {
    return r.stateLu === abbreviation || r.abbreviation === abbreviation;
  });
  return (recs.shift() || {}).state || '';
};

export const getAbbreviation = (state = '') => {
  state = state.toUpperCase().replace(/[^A-Z]/g, '');
  const recs = lookupTable.filter(r => {
    return r.stateLu === state || r.abbreviation === state;
  });
  return (recs.shift() || {}).abbreviation || '';
};

export default lookupTable;

