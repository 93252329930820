import loadHandlerFromString from 'utils/loadHandlerFromString';

const getHandler = (source) => {
  const type = typeof source;
  if (type === 'string') {
    return loadHandlerFromString(source);
  }
  if (type === 'function') {
    return source;
  }
  throw new Error(`Unknown handler format for map got "${type}"`);
};

export default getHandler;
