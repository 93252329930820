import { getAbbreviation } from 'utils/states';
import namecase from 'namecase';

const reCounselorField = /^(counselor|counselor ?name)$/i;

export const getCounselorFromStateZip = (lut, state, zip) => {
  const counselorByZip = zip && lut[zip];
  if (counselorByZip) {
    return namecase(counselorByZip);
  }
  if (state) {
    const counselorByState = lut[getAbbreviation(state)];
    if (counselorByState) {
      return namecase(counselorByState);
    }
  }
  return '';
};

export const getLowestCounselor = ({ counselors, counselorCounts }) => {
  const { counselor } = counselors.reduce(
    (r, counselor) => {
      const count = counselorCounts[counselor];
      if (count < r.count) {
        return {
          count,
          counselor,
        };
      }
      return r;
    },
    { count: Number.MAX_SAFE_INTEGER }
  );
  return namecase(counselor);
};

export const loadCounselorFile = (rows) => {
  const counselors = Array.from(
    new Set(
      rows.map((row) => {
        const keys = Object.keys(row);
        const counselorField = keys
          .filter((fn) => reCounselorField.exec(fn.trim()))
          .shift();
        return row[counselorField];
      })
    )
  );
  const counts = rows.reduce((counts, row) => {
    const keys = Object.keys(row);
    const counselorField = keys
      .filter((fn) => reCounselorField.exec(fn.trim()))
      .shift();
    return Object.assign(counts, { [namecase(row[counselorField])]: 0 });
  }, {});
  const lut = rows.reduce((lut, row) => {
    const keys = Object.keys(row);
    const zipField = keys
      .filter((fn) => /^(zip|zip *code)$/i.exec(fn.trim()))
      .shift();
    const stateField = keys.filter((fn) => /^state$/i.exec(fn.trim())).shift();
    const counselorField = keys
      .filter((fn) => reCounselorField.exec(fn.trim()))
      .shift();
    const key = row[zipField] || getAbbreviation(row[stateField]);
    return Object.assign(lut, {
      [key]: row[counselorField],
    });
  }, {});
  return { lut, counts, counselors };
};

export default loadCounselorFile;
