import getMapperRules from 'utils/getMapperRules';
import getMapperObject from 'utils/getMapperObject';
import getHandler from 'utils/getHandler';

export const mapMapper = (map) => {
  const rules = getMapperRules(map.rules);
  const mapper = {
    type: 'map',
    rules
  };
  return mapper;
};

export const mapJS = (map) => {
  const handler = getHandler(map.handler);
  const mapper = {
    type: 'js',
    handler
  };
  return mapper;
};

export const mapObjectMapper = (map) => {
  const rules = getMapperObject(map.rules);
  const mapper = {
    type: 'object',
    rules
  };
  return mapper;
};

export const mapSimpleMapper = (map) => {
  const mapRules = getMapperRules(map.map);
  const mapper = {
    type: 'simple',
    map: mapRules
  };
  return mapper;
};

const ensureMapperObject = (map) => {
  switch (map.type) {
    case 'map':
    case 'mapper':
      return mapMapper(map);
    case 'js':
    case 'javascript':
      return mapJS(map);
    case 'obj':
    case 'object':
      return mapObjectMapper(map);
    case 'simple':
      return mapSimpleMapper(map);
    default:
  }
  return map;
};

export default ensureMapperObject;
