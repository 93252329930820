import Reform from 'utils/Reform';
import valueFrom from 'utils/valueFrom';
import lowerKeys from 'utils/lowerkeys';
import isNumeric from 'utils/isNumeric';
import isBoolean from 'utils/isBoolean';
import mapValuesSeries from 'async/mapValuesSeries';
import { stripSpecial, stripSpecialKeys } from 'utils/stripSpecial';

const isUndefined = (value) => typeof value === 'undefined';
const makeArray = (value) => (Array.isArray(value) ? value : [value]);

const defaultedValue = (value, defaultValue) =>
  isUndefined(value) ? defaultValue : value;

const findValued = (arr, fun) => {
  const t = Object(arr);
  const len = t.length >>> 0;

  for (let i = 0; i < len; i++) {
    const value = fun(t[i]);
    if (!isUndefined(value)) {
      return value;
    }
  }
};

class Mapper {
  constructor({ map, mappedOnly = false, onProgress }) {
    this._map = map;
    this.mappedOnly = mappedOnly;
    this.onProgress = onProgress;
  }

  mapFormat(item, format, defaultValue) {
    const r = new Reform(format);
    const value = r.reform(item);
    return defaultedValue(value, defaultValue);
  }

  mapReference(item, reference, defaultValue) {
    const references = makeArray(reference);
    const value = findValued(references, (reference) =>
      valueFrom(
        stripSpecial(reference.toLowerCase()),
        stripSpecialKeys(lowerKeys(item))
      )
    );
    return defaultedValue(value, defaultValue);
  }

  mapLookup(item, lookupTable, from, defaultValue) {
    const lookupKey = this.mapReference(item, from);
    const value = lookupTable[lookupKey];
    return defaultedValue(value, defaultValue);
  }

  mapWhen(item, when, defaultFrom, defaultValue) {
    const whens = makeArray(when);
    const whenValue = this.mapReference(item, defaultFrom, '');
    const value = findValued(
      whens,
      ({
        is: _is,
        like: _like,
        greaterthan,
        lessthan,
        gt,
        lt,
        set: newValue
      }) => {
        const srcLike = makeArray(_like)
          .filter((s) => !!s)
          .map((s) => s.trim())
          .join('|');
        const srcIs = makeArray(_is)
          .filter((s) => !!s)
          .map((s) => s.trim())
          .join('|');
        const isIs = !!srcIs;
        const isLike = !!srcLike;
        const reLike = new RegExp(srcLike, 'i');
        const reIs = new RegExp('^' + srcIs + '$', 'i');
        const isGt = greaterthan || gt;
        const isLt = lessthan || lt;
        if (isGt && isNumeric(isGt)) {
          if (isNumeric(whenValue) && +whenValue > +isGt) {
            return newValue;
          }
        }
        if (isLt && isNumeric(isLt)) {
          if (isNumeric(whenValue) && +whenValue < +isLt) {
            return newValue;
          }
        }
        if (isIs && reIs.exec(whenValue)) {
          return newValue;
        }
        if (isLike && reLike.exec(whenValue)) {
          return newValue;
        }
      }
    );
    return defaultedValue(value, defaultValue);
  }

  mapItem(item, { mappedOnly } = {}) {
    if (typeof item !== 'object') {
      return item;
    }
    if (!item) {
      return item;
    }
    if (!isBoolean(mappedOnly)) {
      mappedOnly = this.mappedOnly;
    }

    const out = this._map.reduce(
      (obj, mapItem) => {
        const {
          name,
          value,
          default: defaultValue,
          format,
          from,
          when,
          lookup
        } = mapItem;

        if (lookup) {
          const lookupValue = this.mapLookup(obj, lookup, from, defaultValue);
          return { ...obj, [name]: lookupValue };
        }

        if (when) {
          const whenValue = this.mapWhen(obj, when, from, defaultValue);
          return { ...obj, [name]: whenValue };
        }

        if (from) {
          const referenceValue = this.mapReference(obj, from, defaultValue);
          return { ...obj, [name]: referenceValue };
        }

        if (format) {
          const formatValue = this.mapFormat(obj, format, defaultValue);
          return { ...obj, [name]: formatValue };
        }

        return { ...obj, [name]: defaultedValue(value, defaultValue) };
      },
      { ...item }
    );

    if (mappedOnly) {
      return this._map.reduce(
        (obj, { name }) => ({ ...obj, [name]: out[name] }),
        {}
      );
    }
    return out;
  }

  map(list, options) {
    if (Array.isArray(list)) {
      return list.map((item) => this.mapItem(item, options));
    }
    return this.mapItem(list, options);
  }

  doProgress({ onProgress, ...args }) {
    const progressHandler = onProgress || this.onProgress;
    progressHandler && progressHandler(args);
  }

  async mapAsync(list, options) {
    return new Promise((resolve, reject) => {
      if (!Array.isArray(list)) {
        return resolve(this.mapItem(list, options));
      }
      const count = list.length;

      mapValuesSeries(
        list,
        (item, index, next) => {
          const waitForIt = index % 50 === 0;
          this.doProgress({ ...options, index, count, item });

          if (waitForIt) {
            return setImmediate(() => next(null, this.mapItem(item, options)));
          }
          return next(null, this.mapItem(item, options));
        },
        (err, data) => {
          if (err) {
            return reject(err);
          }

          const recs = [].concat(
            ...Object.values(data) //.filter((l) => l && l.length)
          );
          return resolve(recs);
        }
      );
    });
  }
}

export default Mapper;
