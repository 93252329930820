const defaultedValue = (...args) => {
  //const defaultValue = args.pop();
  return args.reduce((res, value) => {
    if (typeof res !== 'undefined') {
      return res;
    }
    if (typeof value !== 'undefined') {
      return value;
    }
    return res;
  }); //, defaultValue);
};

export default defaultedValue;
