import {
  ResponsiveContainer,
  RadarChart as RERadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  //PolarRadiusAxis,
  Tooltip,
} from 'recharts';

import { getValue, colorPicker } from 'components/Charts/helpers';

const isObject = (v) => {
  if (Array.isArray(v)) {
    return false;
  }
  if (!isNaN(Date.parse(v))) {
    return false;
  }
  return typeof v === 'object';
};

const makeSingleSeries = (rawData) => {
  const data = Object.entries(rawData).map(([name, value]) => ({
    name,
    value: getValue({ value }),
  }));
  return {
    data,
    series: (
      <Radar
        dataKey="value"
        stroke={colorPicker(0)}
        fill={colorPicker(0)}
        fillOpacity={0.6}
      />
    ),
  };
};

const makeSeries = (rawData) => {
  const keys = Object.keys(rawData);
  const isObjectObject = isObject(rawData[keys[0]]);
  if (!isObjectObject) {
    return makeSingleSeries(rawData);
  }
  const data = Object.entries(
    keys.reduce((list, key) => {
      return Object.keys(rawData[key]).reduce((list, name) => {
        const o = list[name] || {};
        o[key] = getValue({ value: rawData[key][name] });
        return { ...list, [name]: o };
      }, list);
    }, {})
  ).map(([name, values]) => ({ ...values, name }));
  const series = keys.map((key, index) => (
    <Radar
      key={key}
      name={key}
      dataKey={key}
      stroke={colorPicker(index)}
      fill={colorPicker(index)}
      fillOpacity={0.6}
    />
  ));
  return {
    data,
    series,
  };
};

const RadarChart = ({ data: rawData, height = 300 }) => {
  const { data, series } = makeSeries(rawData);
  return (
    <ResponsiveContainer width="100%" minHeight={height}>
      <RERadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
        <PolarGrid />
        <PolarAngleAxis dataKey="name" />
        {series}
        <Tooltip />
      </RERadarChart>
    </ResponsiveContainer>
  );
};

export default RadarChart;
