import Header from 'components/Cards/Header';
import Body from 'components/Cards/Body';
import { Card as BSCard } from 'react-bootstrap';

const Card = ({ title, description, children, props }) => (
  <BSCard>
    <Header {...{ title, description }} />
    <Body {...props}>{children}</Body>
  </BSCard>
);

export default Card;
