import * as stringify from 'csv-stringify';
import downloadFile from 'utils/downloadFile';
import { calculateColumns, getRecordMembers } from 'components/Tables/helpers';

const ensureColumns = (columns, data) => {
  if (Array.isArray(columns)) {
    return columns;
  }
  return calculateColumns(getRecordMembers(data)).filter(
    ({ dataField, text }) => dataField && text
  );
};

const downloadCSV = ({ data, columns, filename }) => {
  try {
    stringify(
      data,
      {
        columns: ensureColumns(columns, data).map(
          ({ dataField, text, key, header }) => ({
            key: key || dataField,
            header: header || text,
          })
        ),
        header: true,
      },
      (err, csv) => {
        if (err) {
          return alert(err);
        }
        downloadFile(filename || 'download.csv', csv);
      }
    );
  } catch (e) {
    console.error(e);
    console.error({
      columns,
      data,
      filename,
      ensuredColumns: ensureColumns(columns, data),
    });
    throw e;
  }
};

export default downloadCSV;
