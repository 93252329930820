import { Container, Row, Col } from 'react-bootstrap';
import Card from 'components/Cards/Card';
import Icon from 'components/Icons/Icon';

const BasicPage = ({ caption, title, description, icon, children }) => (
  <Container>
    <Row>
      <Col>
        <Card
          title={
            <>
              {icon ? <Icon icon={icon} /> : <></>}
              {caption || title}
            </>
          }
          description={description}
        >
          {children}
        </Card>
      </Col>
    </Row>
  </Container>
);

export default BasicPage;
