import { useState } from 'react';
import { Group } from 'components/Forms/Form';
import { Textarea } from 'components/Forms/Form';
import { loadCSV, loadXLSX } from 'utils/file';
import YAML from 'js-yaml';
import ImportFileButton from 'components/Buttons/ImportFile';
import { YAML_FILE_EXTENSIONS, CSV_FILE_EXTENSIONS } from 'utils/const';

const sourceFrom = (s) => {
  if (typeof s === 'string') {
    return s;
  }
  return YAML.dump(s);
};

const ColumnsEditor = ({
  source: defaultSource,
  columns: columnsSource,
  //accept = `${YAML_FILE_EXTENSIONS},${CSV_FILE_EXTENSIONS},.xls,.xlsx`,
  accept = `${YAML_FILE_EXTENSIONS},${CSV_FILE_EXTENSIONS}`,
  filename = 'download.yaml',
  ...props
}) => {
  const [source, setSource] = useState(
    sourceFrom(defaultSource || columnsSource)
  );

  //console.log('defaultSource || columnsSource', defaultSource || columnsSource);

  const doImport = async (source, { name }) => {
    const type = (name.split('.').pop() || '').toLowerCase();

    const objToRec = (obj) =>
      Object.entries(obj).map(([header, key]) => ({
        header: header || key,
        key: key || header,
      }));

    switch (type) {
      case 'csv':
        const csv = (await loadCSV(source)).data;
        return setSource(YAML.dump(objToRec(csv[0])));
      case 'xls':
      case 'xlsx':
        const xls = loadXLSX(source);
        return setSource(YAML.dump(objToRec(xls[0])));
      default:
        return setSource(YAML.dump(YAML.load(source)));
    }
  };

  const handleChange = ({ target }) => {
    const { value } = target;
    setSource(value);
  };

  return (
    <Group>
      <ImportFileButton onImport={doImport} accept={accept}>
        Import
      </ImportFileButton>
      <Textarea {...props} value={source} onChange={handleChange} />
    </Group>
  );
};

export default ColumnsEditor;
