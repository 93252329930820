import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'components/Cards/Card';
import ErrorPanel from 'components/Errors/Error';
import Provider from 'data/Provider';
import getEmmaListResource from 'data/getEmmaListResource';
import Table from 'components/Tables/Table';
import LinkButton from 'components/Buttons/Link';
import { useHistory } from 'react-router-dom';
import ActionInputEdit from 'components/Editors/ActionInputEdit';
import strToArray from 'utils/strToArray';

const dateSort = (a, b) => {
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }
  return Date.parse(b) - Date.parse(a);
};

const props = {
  caption: 'Overview',
  description: 'Shows an overivew of the mailings',
  icon: 'nc-puzzle-10',
  path: '/account/:accountId',
  isDefault: true,
  exact: true
};

const reIsNotValidId = /[^0-9]/g;
const isNotValidId = (id) => !!reIsNotValidId.exec(`${id}`);
const validateIds = (ids) => {
  const invalidIds = ids.filter(isNotValidId);
  if (invalidIds.length) {
    return new Error(`Invalid mailing id(s) "${invalidIds}"`);
  }
  return ids;
};

const exportMailings = ({ accountId, mailingIds, redirect }) => {
  const ids = Array.from(new Set(mailingIds)).join(',');
  const dest = `/admin/account/${accountId}/export/mailings/${ids}`;
  redirect(dest);
};

const exportMailingsFromRecords = ({ data, selected, redirect }) => {
  const accountId = (selected || data)[0].accountId;
  const mailingIds = (selected || data).map(({ id }) => id);
  return exportMailings({ accountId, mailingIds, redirect });
};

const Component = ({ client, ...props }) => {
  const history = useHistory();
  const redirect = (to) => history.push(to);
  const [error, setError] = useState();

  const { accountId } = client;
  const columns = [
    { key: 'name' },
    { key: 'id' },
    { key: 'sendStarted', header: 'sent' },
    { key: 'recipientCount' },
    {
      key: 'actions',
      isDummyField: true,
      formatter(field, { sendStarted, id }) {
        return sendStarted ? (
          <LinkButton to={`/admin/account/${accountId}/reports/mailing/${id}`}>
            View
          </LinkButton>
        ) : (
          <></>
        );
      }
    }
  ];

  return (
    <Provider
      client={client}
      provide={{
        mailings: getEmmaListResource({ resource: 'mailings', accountId })
      }}
      view={({ mailings }) => (
        <Container fluid>
          <ErrorPanel error={error} />
          <Row>
            <Col lg="12" sm="12">
              <Card title={client.name}>
                <Container>
                  <Row>
                    <Col lg="12" sm="12">
                      <ActionInputEdit
                        icon="nc-cloud-download-93"
                        placeholder="Mailing ID's"
                        hint="Enter mailing id to view"
                        onButtonClick={(strIds) => {
                          const mailingIds = validateIds(
                            strToArray(strIds, {
                              noObjectLoad: true
                            })
                          );
                          if (mailingIds instanceof Error) {
                            return setError(mailingIds);
                          }
                          if (mailingIds.length) {
                            try {
                              exportMailings({
                                accountId: client.accountId,
                                mailingIds: mailingIds,
                                redirect
                              });
                            } catch (e) {
                              setError(e);
                            }
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" sm="12">
                      <Table
                        data={mailings.sort(
                          (
                            { sendStarted: sendStartedA },
                            { sendStarted: sendStartedB }
                          ) => dateSort(sendStartedA, sendStartedB)
                        )}
                        columns={columns}
                        onExport={(props) => {
                          setError();
                          try {
                            exportMailingsFromRecords({ ...props, redirect });
                          } catch (e) {
                            setError(e);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </Container>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    />
  );
};

const Overview = {
  ...props,
  Component
};

export default Overview;
