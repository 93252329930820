import request from 'data/request';

const getUrlFromOptions = (opts) => {
  const type = typeof opts;
  if (type === 'string') {
    return opts;
  }
  return opts.uri || opts.url;
};

const getUriResource = async (opts, updateFunction) => {
  const url = getUrlFromOptions(opts);
  //console.log(opts, url);
  const resource = url.split('?').shift();
  updateFunction(`Fetching ${resource}`);
  const result = await request(opts);
  updateFunction(`Fetched ${resource}`);
  return result.data;
};

export default getUriResource;
