import Overview from 'views/Overview';
import Icons from 'views/Icons';
import MailingsReport from 'views/Reports/Mailings';
import GeoFenceReport from 'views/Reports/GeoFence';
import MailingsExport from 'views/Export/Mailings';
import MergeList from 'views/Lists/Merge';
import ListMapper from 'views/Lists/Mapper';
import CombineLists from 'views/Lists/CombineLists';
import FuzzyMatcher from 'views/Lists/FuzzyMatcher';
import ConfigureClient from 'views/ConfigureClient';
import ConfigureSystem from 'views/ConfigureSystem';
import Users from 'views/Users';
import User from 'views/User';
import Mapper from 'views/Mapper';

const routes = [
  Overview,
  Icons,
  MailingsReport,
  GeoFenceReport,
  MailingsExport,
  ListMapper,
  MergeList,
  FuzzyMatcher,
  CombineLists,
  ConfigureClient,
  Users,
  User,
  ConfigureSystem,
  Mapper
].map(
  ({
    Component,
    caption: name,
    description,
    icon,
    path,
    isAdmin = false,
    isDefault = false,
    exact = false
  }) => ({
    path,
    name,
    description,
    isAdmin,
    icon: `nc-icon ${icon}`,
    Component,
    layout: '/admin',
    isDefault,
    exact
  })
);

export const defaultRoute = routes.filter((route) => route.isDefault).shift();

export default routes;
