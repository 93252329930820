import mapValuesSeries from 'async/mapValuesSeries';

const JSMapper = async ({ data, handler, onProgress, ...options }) => {
  const mapItem = ({ data, item, index, count, options }) => {
    return handler && handler({ data, item, index, count, ...options });
  };
  const doProgress = ({ index, count, item, options }) =>
    onProgress && onProgress({ index, count, item, ...options });

  return new Promise((resolve, reject) => {
    if (!Array.isArray(data)) {
      return resolve(
        mapItem({ data: [data], item: data, index: 0, count: 1, options })
      );
    }
    const count = data.length;

    mapValuesSeries(
      data,
      (item, index, next) => {
        const waitForIt = index % 50 === 0;
        doProgress({ options, index, count, item });

        if (waitForIt) {
          return setImmediate(() =>
            next(null, mapItem({ item, index, count, options }))
          );
        }
        return next(null, mapItem({ item, index, count, options }));
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }

        const recs = [].concat(
          ...Object.values(data) //.filter((l) => l && l.length)
        );
        return resolve(recs);
      }
    );
  });
};

export default JSMapper;
