import { useAuth0 } from '@auth0/auth0-react';
import Error from 'components/Errors/Error';
import Loading from 'components/Loader/Loading';
import LoginButton from 'components/Auth/Auth0Login';
//import { setToken } from 'data/auth';

const AuthPanel = ({ autoLogin, children }) => {
  const { isLoading, error, isAuthenticated, loginWithRedirect } = useAuth0();
  //const { getAccessTokenSilently } = useAuth0();

  if (error) {
    return <Error error={error.message} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (autoLogin && !isAuthenticated) {
    return loginWithRedirect();
  }

  return isAuthenticated ? <div>{children}</div> : <LoginButton />;
};

export default AuthPanel;
