import { Modal as BSModal } from 'react-bootstrap';
import Button from 'components/Buttons/Button';
import { isReact } from 'utils/react';

const DEFAULT_BUTTONS = {
  close: { text: 'Close' },
};

const getButtonDefinition = (definition) => {
  if (typeof definition === 'string') {
    return { text: definition };
  }
  const { text, caption, ...props } = definition;
  return {
    text: text || caption,
    ...props,
  };
};

const makeButtons = (buttonDefinitions, handleClose) => {
  if (isReact(buttonDefinitions)) {
    return buttonDefinitions;
  }
  if (Array.isArray(buttonDefinitions)) {
    return buttonDefinitions.map((def) => makeButtons(def, handleClose));
  }
  return Object.entries(buttonDefinitions).map(([key, definition]) => {
    const { text, className, ...props } = getButtonDefinition(definition);
    return (
      <Button key={key} onClick={handleClose} className={className} {...props}>
        {text}
      </Button>
    );
  });
};

const Modal = ({
  closeButton,
  saveButton,
  visible,
  onClose,
  title,
  children,
  buttons: buttonDefinitions = DEFAULT_BUTTONS,
}) => {
  const handleClose = (e) => {
    e && e.stopPropagation && e.stopPropagation();
    onClose && onClose();
  };
  const buttons = makeButtons(buttonDefinitions, handleClose);

  return (
    <BSModal show={visible} onHide={handleClose}>
      <BSModal.Header closeButton>
        <BSModal.Title>{title}</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>{children}</BSModal.Body>
      <BSModal.Footer>{buttons}</BSModal.Footer>
    </BSModal>
  );
};

export default Modal;
