import * as stringify from 'csv-stringify';

import downloadFile from 'utils/downloadFile';
import JSZip from 'jszip';
import lowerkeys from 'utils/lowerkeys';

import { fixDates, fixRecordDates } from 'utils/fixdates';

import mapLimit from 'async/mapLimit';

const lowerColumnKeys = (columns) => {
  return columns.map((col) => {
    if (typeof col === 'object' && col.key) {
      return {
        ...col,
        key: `${col.key}`.toLowerCase(),
      };
    }
    return col;
  });
};

const blobCSVData = async (data, columns) =>
  new Promise((resolve, reject) => {
    const options =
      Array.isArray(columns) && columns.length
        ? {
            header: true,
            columns,
          }
        : {
            header: true,
          };

    stringify(data, options, (err, csv) => {
      if (err) {
        return reject(err);
      }
      resolve(csv);
    });
  });

const explodeData = (data) =>
  data.map(({ fields, link = {}, ...data }) => ({
    ...data,
    ...fields,
    linkName: link.name,
    linkTarget: link.target,
  }));

const expandLinks = (list, linksLu) =>
  Array.isArray(list)
    ? list.map(({ linkId, ...record }) =>
        linkId
          ? {
              ...record,
              linkId,
              link: linksLu[linkId],
            }
          : record
      )
    : [];

const getClickSummaryReport = (clicksData) => {
  return Object.entries(
    clicksData
      .map(({ email, timestamp, fields, link = {} }) => ({
        email,
        timestamp,
        ...fields,
        linkName: link.name,
        linkTarget: link.target,
      }))
      .reduce((lut, click) => {
        const { email } = click;

        return { ...lut, [email]: (lut[email] || []).concat(click) };
      }, {})
  ).reduce((list, [email, clicks]) => {
    const link = Array.from(
      new Set(clicks.map(({ linkName }) => linkName))
    ).join([', ']);
    const { linkName, linkTarget, ...rec } = clicks[0];

    return list.concat(fixRecordDates({ ...rec, link }));
  }, []);
};

const downloadDataset = async ({
  filename = 'dataset.zip',
  response,
  //opens,
  optouts,
  clicks,
  links,
  client,
}) => {
  const basename = filename.split('.').slice(0, -1).join('.');
  const linksLu = links.reduce(
    (links, link) => ({ ...links, [link.id]: link }),
    {}
  );

  const files = await mapLimit(
    Object.entries({
      response: [response],
      //opens: expandLinks(opens, linksLu),
      optouts: expandLinks(optouts, linksLu),
      clicks: expandLinks(clicks, linksLu),
      links,
    }).map(([fn, data]) => ({
      filename: `${basename}-${fn}.csv`,
      data,
    })),
    3,
    async ({ filename, data }) => ({
      filename,
      contents: await blobCSVData(fixDates(explodeData(data))),
    })
  );

  files.push({
    filename: `${basename}-click_summary.csv`,
    contents: await blobCSVData(
      lowerkeys(getClickSummaryReport(expandLinks(clicks, linksLu))),
      lowerColumnKeys(client.columns)
    ),
  });

  var zip = new JSZip();
  files.forEach(({ filename, contents }) => zip.file(filename, contents));
  zip.generateAsync({ type: 'blob' }).then(function (content) {
    downloadFile(filename, content);
  });
};

export default downloadDataset;
