import moment from 'moment';

const reIsDateCol = /(dob|date)/i;
const reIsDateOkay = /[\\/-]/i;

export const remapDateValue = (value) => {
  try {
    const sValue = `${value}`;
    const l = sValue.length;
    if (l === 7) {
      const shortMonthDate = moment(`0${value}`, 'MMDDYYYY').format(
        'MM/DD/YYYY'
      );
      return shortMonthDate;
    }
    if (l === 8) {
      const longMonthDate = moment(sValue, 'MMDDYYYY').format('MM/DD/YYYY');
      return longMonthDate;
    }
  } catch (e) {
    console.error(e);
    return value;
  }
};

export const isDateColNeedsCooresed = (key, value) =>
  reIsDateCol.exec(key) && !reIsDateOkay.exec(value);

export const fixRecordDates = (record) =>
  Object.entries(record).reduce((obj, [key, value]) => {
    if (typeof value !== 'string') {
      return { ...obj, [key]: value };
    }
    if (isDateColNeedsCooresed(key, value)) {
      // It's a date column and the value doesn't have / \ or -
      return { ...obj, [key]: remapDateValue(value) };
    }
    return { ...obj, [key]: value };
  }, {});

export const fixDates = (data) => data.map(fixRecordDates);
