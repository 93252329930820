import classNames from 'classnames';

import Icon from 'components/Icons/Icon';

import 'components/Links/links.css';

import NavLink from 'components/Links/NavLink';
import {
  makeHref,
  isOffsiteLink,
  offsiteProps
} from 'components/Links/helpers';

const Link = ({
  href,
  to,
  children,
  className: customClassName = '',
  ...props
}) => {
  const url = makeHref({ href, to });
  const className = classNames('link', customClassName);
  const isOffsite = isOffsiteLink({ url });

  if (!isOffsite) {
    return (
      <NavLink to={url} className={className} {...props}>
        {children}
      </NavLink>
    );
  }

  return (
    <a
      href={url}
      {...offsiteProps({
        url,
        className
      })}
      {...props}
    >
      {children}
      <Icon icon="nc-air-baloon" />
    </a>
  );
};

export default Link;
