import { Form as BSForm } from 'react-bootstrap';
import * as BSFloatingLabel from 'react-bootstrap/FloatingLabel';
import FormControl from 'react-bootstrap/FormControl';

export const { Floating, Group, Label, Text, Control, Check, Range, Select } =
  BSForm;

export const Option = (props) => <option {...props} />;

export const Textarea = (props) => <Control as="textarea" {...props} />;

export const Switch = ({ children, ...props }) => (
  <Check type="switch" id={props.name} label={children} {...props} />
);
export const Checkbox = ({ children, ...props }) => (
  <Check type="checkbox" id={props.name} label={children} {...props} />
);
export const Radiobutton = ({ children, ...props }) => (
  <Check type="radio" id={props.name} label={children} {...props} />
);
export const File = (props) => <Control size="sm" type="file" {...props} />;

export const Color = (props) => (
  <Control size="sm" type="color" title="Pick a color" {...props} />
);

export const Input = (props) => <Control size="sm" type="input" {...props} />;

export const FloatingLabel = BSFloatingLabel;
export const { Feedback } = FormControl;

const handleSubmit = (onSubmit, props) => (e) => {
  e.preventDefault();
  const form = e.target;
  const formData = new FormData(form);
  const data = Array.from(formData.entries()).reduce((obj, [rawKey, value]) => {
    const key = rawKey.trim().replace(/\[]$/, '');
    if (Reflect.has(obj, key)) {
      if (Array.isArray(obj[key])) {
        return { ...obj, [key]: [...obj[key], value] };
      }
      return { ...obj, [key]: [obj[key], value] };
    }
    return { ...obj, [key]: value };
  }, {});

  onSubmit && onSubmit(data, props);
};

const Form = ({ onSubmit, children, ...additionalProps }) => (
  <BSForm onSubmit={handleSubmit(onSubmit, additionalProps)}>{children}</BSForm>
);

export default Form;
