import loadObjectFromString from 'utils/loadObjectFromString';

const getMapperObject = (source) => {
  if (typeof source === 'object') {
    return source;
  }
  try {
    return loadObjectFromString(source);
  } catch (e) {
    // eslint-disable-next-line no-new-func
    const f = new Function('module', source);
    const module = {};
    const result = f(module);
    if (typeof result === 'object') {
      return result;
    }
    return module.exports;
  }
};

export default getMapperObject;
