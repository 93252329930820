import { Alert } from 'react-bootstrap';

const isError = (obj) =>
  Object.prototype.toString.call(obj) === '[object Error]';

const getErrorString = (error) => {
  console.error(error);
  if (isError(error)) {
    return error.toString();
  }
  if (typeof error === 'object') {
    if (error.error) {
      return error.error;
    }
    if (error.errmsg) {
      return error.errmsg;
    }
    return JSON.stringify(error);
  }
  return error;
};

const Error = ({ error }) =>
  error ? <Alert variant="danger">{getErrorString(error)}</Alert> : <></>;

export default Error;
