import { toRegularExpression } from 'utils/regex';

export const getField = (list, ...searchFor) => {
  const defaultTo = searchFor.length > 1 ? searchFor.pop() : '';
  const recs = Array.isArray(list) ? list : [list];
  if (recs && recs.length) {
    let i = 0;
    const reIsField = toRegularExpression(searchFor);
    while (i < recs.length) {
      const rec = recs[i];
      const keys = Object.keys(rec);
      const fields = keys.filter((k) => reIsField.exec(k));
      if (fields.length) {
        return fields.shift();
      }
      i++;
    }
    return defaultTo;
  }

  return defaultTo;
};
