import trueType from 'utils/trueType';

const isTheSame = (a, b, maxDepth = 100) => {
  if (maxDepth < 0) {
    return false;
  }
  if (a === b) {
    return true;
  }
  const typeA = trueType(a);
  const typeB = trueType(b);
  if (typeA !== typeB) {
    return false;
  }
  if (typeA === 'object') {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    if (!isTheSame(aKeys, bKeys)) {
      return false;
    }
    return aKeys.every((key) => {
      return isTheSame(a[key], b[key], maxDepth - 1);
    });
  }
  if (typeA === 'array') {
    if (a.length !== b.length) {
      return false;
    }
    return a.every((item, index) => isTheSame(item, b[index], maxDepth - 1));
  }
  if (typeA === 'date') {
    return a.getTime() === b.getTime();
  }
  if (typeA === 'regex') {
    return a.toString() === b.toString();
  }
  if (typeA === 'null') {
    return true;
  }
  if (typeA === 'undefined') {
    return true;
  }
  return a === b;
};

export default isTheSame;
