import { useState } from 'react';
//*
//import YamlEditor from 'components/Editors/Yaml';
import { Group, Select } from 'components/Forms/Form';

import ImportFileButton from 'components/Buttons/ImportFile';

import YAML from 'js-yaml';

import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';

export const getFiletypesForType = (type) => {
  switch (type) {
    case 'map':
    case 'mapper':
      return '.json,.yml,.yaml';
    case 'js':
    case 'javascript':
      return '.js';
    case 'obj':
    case 'object':
      return '.json,.yml,.yaml';
    case 'simple':
      return '.yml,.yaml';
    default:
      return '.yml,.yaml';
  }
};

export const getEditorMode = (type) => {
  switch (type) {
    case 'map':
    case 'mapper':
      return 'yaml';
    case 'js':
    case 'javascript':
      return 'javascript';
    case 'obj':
    case 'object':
      return 'javascript';
    case 'simple':
      return 'yaml';
    default:
      return 'yaml';
  }
};

export const getPropertyForType = (type) => {
  switch (type) {
    case 'map':
    case 'mapper':
      return 'rules';
    case 'js':
    case 'javascript':
      return 'handler';
    case 'obj':
    case 'object':
      return 'rules';
    case 'simple':
      return 'map';
    default:
      return 'map';
  }
};

export const getValueForType = ({ type, map, rules, handler }) => {
  switch (type) {
    case 'map':
    case 'mapper':
      return typeof rules === 'string' ? rules : YAML.dump(rules);
    case 'js':
    case 'javascript':
      return handler;
    case 'obj':
    case 'object':
      return typeof rules === 'string' ? rules : JSON.stringify(rules, null, 2);
    case 'simple':
      return typeof map === 'string' ? map : JSON.stringify(map, null, 2);
    default:
      return typeof map === 'string' ? map : JSON.stringify(rules, null, 2);
  }
};

export const upperFirst = (s) => s[0].toUpperCase() + s.slice(1);

export const Editor = ({ type, name, value, onChange }) => {
  return (
    <>
      <textarea
        style={{ display: 'none' }}
        name={`${name}${upperFirst(getPropertyForType(type))}`}
        value={value}
        readOnly={true}
      />
      <AceEditor
        mode={getEditorMode(type)}
        theme="github"
        editorProps={{ $blockScrolling: true }}
        onChange={onChange}
        value={value}
      />
    </>
  );
};

const MapEditor = ({
  mapSource = '[]',
  rulesSource = '{}',
  handlerSource = 'return item;',
  type: editorType = 'simple',
  name,
  ...props
}) => {
  const [type, setMapType] = useState(editorType);
  const [map, setMap] = useState(mapSource);
  const [rules, setRules] = useState(rulesSource);
  const [handler, setHandler] = useState(handlerSource);
  const mapTypeChanged = (event) => {
    setMapType(event.target.value);
  };

  const editor = (
    <Editor
      {...{
        type,
        name,
        value: getValueForType({ type, map, rules, handler }),
        onChange(newValue) {
          switch (getPropertyForType(type)) {
            case 'map':
              return setMap(newValue);
            case 'rules':
              return setRules(newValue);
            case 'handler':
              return setHandler(newValue);
            default:
              return setMap(newValue);
          }
        }
      }}
    />
  );

  const doImport = (source) => {
    switch (getPropertyForType(type)) {
      case 'map':
        return setMap(source);
      case 'rules':
        return setRules(source);
      case 'handler':
        return setHandler(source);
      default:
        return setMap(source);
    }
  };

  return (
    <Group>
      <Select name={`${name}Type`} value={type} onChange={mapTypeChanged}>
        <option value="simple">Simple</option>
        <option value="mapper">Mapper</option>
        <option value="javascript">JavaScript per Row</option>
        <option value="object">Object Mapper</option>
      </Select>
      <ImportFileButton onImport={doImport} accept={getFiletypesForType(type)}>
        Import
      </ImportFileButton>
      {editor}
    </Group>
  );
};

export default MapEditor;
//*/

/*
import Button from 'components/Buttons/Button';
import Icon from 'components/Icons/Icon';
import Error from 'components/Errors/Error';
import DeleteButton from 'components/Buttons/Delete';
import ImportFileButton from 'components/Buttons/ImportFile';
import { Table as BootstrapTable, Form } from 'react-bootstrap';
import { Input, Textarea, Label, Group } from 'components/Forms/Form';
import { YAML_FILE_EXTENSIONS } from 'utils/const';
import downloadFile from 'utils/downloadFile';
import YAML from 'js-yaml';
import loadObjectFromString from 'utils/loadObjectFromString';
import enforceArray from 'utils/enforceArray';
import { useState } from 'react';
import LabeledEdit from 'components/Editors/LabeledEdit'

const UnknownEditor = ({ name, ...props }) => {
  console.log({ name, ...props });
  return <Error error={`Unknown editor type for field "${name}"`} />;
};

const MapNameEditor = ({ name = 'name', defaultValue }) => (
  <LabeledEdit label="Name">
    <Input name={name} defaultValue={defaultValue} />
  </LabeledEdit>
);

const DefaultValue = ({ default: defaultValue }) => (
  <LabeledEdit label="Default Value">
    <Input name="default" defaultValue={defaultValue} />
  </LabeledEdit>
);

const MapLookup = UnknownEditor;

const MapWhen = UnknownEditor;

const MapFrom = ({ name, from, ...props }) => (
  <div>
    <MapNameEditor name="name" defaultValue={name} />
    <LabeledEdit label="From">
      <Textarea name="from" defaultValue={from} />
    </LabeledEdit>
    <DefaultValue {...props} />
  </div>
);

const MapFormat = ({ name, format }) => (
  <div>
    <MapNameEditor name="name" defaultValue={name} />
    <LabeledEdit label="Format">
      <Input name="format" defaultValue={format} />
    </LabeledEdit>
  </div>
);

const MapValue = ({ name, value, ...props }) => (
  <div>
    <MapNameEditor name="name" defaultValue={name} />
    <LabeledEdit label="Value">
      <Input name="value" defaultValue={value} />
    </LabeledEdit>
  </div>
);

const MapPropEditor = (props) => {
  const {
    name,
    value,
    default: defaultValue,
    format,
    from,
    when,
    lookup,
  } = props;
  if (lookup) {
    return <MapLookup {...props} />;
  }

  if (when) {
    return <MapWhen {...props} />;
  }

  if (from) {
    return <MapFrom {...props} />;
  }

  if (format) {
    return <MapFormat {...props} />;
  }

  if (typeof value !== 'undefined') {
    return <MapValue {...props} />;
  }

  return <UnknownEditor {...props} />;
};

const MapEditorRows = ({ map }) =>
  map.map((props) => (
    <Group key={props.name}>
      <MapPropEditor {...props} />
    </Group>
  ));

const MapEditor = ({ map = '[]', ...props }) => (
  <MapEditorRows map={enforceArray(map)} {...props} />
);

export default MapEditor;
//*/
