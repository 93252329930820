import { Container, Row, Col, Dropdown, Pagination } from 'react-bootstrap';

const noop = () => {};

const windowValue = (n, size, maxVal) => {
  const nMin = n - size;
  const cMin = Math.max(0, nMin);
  const nMax = cMin + size + size + 1;
  const max = Math.min(maxVal, nMax);
  const min = Math.max(0, max - size - size - 1);
  return [min, max];
};

const TablePaginator = ({ data, pageSize, page, setPageSize, setPage }) => {
  if (!data || data.length === 0) {
    return <></>;
  }
  const totalPages = Math.ceil(data.length / pageSize);
  if (totalPages === 1) {
    return <></>;
  }
  const items = new Array(totalPages)
    .fill(undefined)
    .map((_, i) => (
      <Pagination.Item
        key={i + 1}
        active={i + 1 === page}
        onClick={() => setPage(i + 1)}
      >
        {i + 1}
      </Pagination.Item>
    ))
    .slice(...windowValue(page - 1, 2, totalPages));

  return (
    <Container style={{ padding: 0 }}>
      <Row>
        <Col>
          <Dropdown>
            <Dropdown.Toggle className="btn btn-fill btn-primary" size="sm">
              {pageSize}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setPageSize(10)}>10</Dropdown.Item>
              <Dropdown.Item onClick={() => setPageSize(20)}>20</Dropdown.Item>
              <Dropdown.Item onClick={() => setPageSize(30)}>30</Dropdown.Item>
              <Dropdown.Item onClick={() => setPageSize(40)}>40</Dropdown.Item>
              <Dropdown.Item onClick={() => setPageSize(50)}>50</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col>
          <Pagination size="sm" style={{ float: 'right' }}>
            <Pagination.First onClick={() => setPage(1)} />
            <Pagination.Prev
              onClick={() => (page > 1 ? setPage(page - 1) : noop())}
            />
            {items}
            <Pagination.Next
              onClick={() => (page < totalPages ? setPage(page + 1) : noop())}
            />
            <Pagination.Last onClick={() => setPage(totalPages)} />
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
};

export default TablePaginator;
