import classNames from 'classnames';

import { NavLink as RRNavLink } from 'react-router-dom';

const NavLink = ({ children, className = '', ...props }) => (
  <RRNavLink className={classNames('link', className)} {...props}>
    {children}
  </RRNavLink>
);

export default NavLink;
