import axios from 'axios';

//import { getToken } from 'data/auth';

const getOptions = async (opts) =>
  typeof opts === 'string'
    ? { url: opts }
    : { url: opts.url || opts.uri, ...opts };

const request = async (opts) => {
  const options = await getOptions(opts);
  const {
    accessToken,
    headers: passedHeaders = {},
    method = 'get',
    ...otherOptions
  } = options;
  /*
  const token = getToken();
  const headers = { token, ...passedHeaders };
  const accessToken = await getAccessTokenSilently({
    authorizationParams: {
      audience: process.env.REACT_APP_AUTH0_AUDIANCE, // Value in Identifier field for the API being called.
      scope: 'profile email read:email', // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
    },
  });
*/
  const headers = {
    //'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
    ...passedHeaders,
  };

  const config = { method, ...otherOptions, headers };
  //console.log(config);
  return axios(config);
};

export default request;
