import React, { useRef, useState } from 'react';
import Button from 'components/Buttons/Button';

const MultipleFileUploader = ({
  uploadFilesChanged = () => {},
  children,
  caption = 'Pick files',
  ...props
}) => {
  const [dragActive, setDragActive] = useState(false);

  const handleFiles = (files) => {
    const uploaded = [];
    Array.from(files).some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        // can return true here at any time to stop the loop
      }
      return false;
    });
    uploadFilesChanged(uploaded);
  };

  const handleFileEvent = (e) => handleFiles(e.target.files);

  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      // handleFiles(e.dataTransfer.files);
      hiddenFileInput.current.files = event.dataTransfer.files;
      handleFiles(event.dataTransfer.files);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.type === 'dragenter' || e.type === 'dragover') {
      return setDragActive(true);
    }
    if (e.type === 'dragleave') {
      return setDragActive(false);
    }
  };

  return (
    <>
      <input
        type="file"
        {...props}
        onChange={handleFileEvent}
        ref={hiddenFileInput}
        style={{ display: 'none' }}
      />
      <Button
        className={dragActive ? 'btn-dragover' : ''}
        onClick={handleClick}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        {children || caption}
      </Button>
    </>
  );
};

export default MultipleFileUploader;
