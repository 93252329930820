import YAML from 'js-yaml';
import isYAML from 'utils/isYAML';
import isJSON from 'utils/isJSON';

export const strToArray = (str) => {
  if (Array.isArray(str)) {
    return str;
  }
  if (typeof str !== 'string') {
    return [];
  }
  if (str.trim() === '') {
    return [];
  }
  const reSplitOn = /[\r\n\t,]+/g;
  return str
    .split(reSplitOn)
    .map((s) => s.trim())
    .filter((s) => !!s);
};

const loadObjectFromString = (source) => {
  if (isJSON(source)) {
    return JSON.parse(source);
  }
  if (isYAML(source)) {
    return YAML.load(source);
  }
  return strToArray(source);
};

export default loadObjectFromString;
