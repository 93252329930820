import * as mime from 'mime-types';
import { saveAs } from 'file-saver';

const downloadFile = (filename, contents) => {
  const type = mime.lookup(filename);
  const blob = new Blob([contents], { type });
  saveAs(blob, filename);
};

export default downloadFile;
