import forceArray from 'utils/forceArray';
import Papa from 'papaparse';
import XLSX from 'xlsx';

export const getFileContent = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      resolve(evt.target.result);
    };
    reader.onerror = (evt) => {
      reject(evt);
    };
    reader.readAsText(file);
  });

export const getFilesContents = (files) =>
  Promise.all(forceArray(files).map(getFileContent));

export const loadXLSX = (source) => {
  const wb = XLSX.read(source, { raw: true });
  const sheetname = wb.SheetNames[0];
  const ws = wb.Sheets[sheetname];
  const rows = XLSX.utils.sheet_to_json(ws);
  return rows;
};

export const loadCSV = async (source) =>
  new Promise((resolve, reject) => {
    Papa.parse(source, {
      header: true,
      dynamicTyping: false,
      complete(results) {
        resolve(results);
      },
      error(err) {
        console.error('error', err);
        reject(err);
      },
    });
  });

export const loadCSVFile = async (file) => {
  const contents = await getFileContent(file);
  return await loadCSV(contents);
};
