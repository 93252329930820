export const standardizeZip = (rec) => {
  if (typeof rec === 'string') {
    const [pre, post] = rec.split('-');
    return { zip: pre, plusfour: post };
  }
  if (rec && rec.plusfour) {
    return rec;
  }
  const { zip = '', plusfour } = rec;
  const [pre, post] = ('' + zip).split('-');
  return { zip: pre, plusfour: post || plusfour };
};

export const compareZips = (raw1, raw2) => {
  const zip1 = standardizeZip(raw1);
  const zip2 = standardizeZip(raw2);
  if (zip1.zip !== zip2.zip) {
    return false;
  }
  if (zip1.plusfour && zip2.plusfour) {
    if (zip1.plusfour !== zip2.plusfour) {
      return false;
    }
  }
  return true;
};
