import Mapper from 'utils/Mapper';
import JSMapper from 'utils/JSMapper';
import ObjectMapper from 'utils/ObjectMapper';
import SimpleMapper from 'utils/SimpleMapper';
import ensureMapperObject from 'utils/ensureMapperObject';

/*
{
  type: 'simple',
  map: [
    {header1: "field", header2: "field"},
    {header1: "field1, field2", header2: "field"}
  ]
}

{
  type: 'mapper',
  rules: [{...}]
}

{
  type: 'js',
  handler: function({data, item, index, count, ...options}){}
}

{
  type: 'object',
  map: {}
}
*/

export const mapUsingMapper = async ({
  list,
  map: mapSource,
  mappedOnly = false,
  onProgress = () => {},
}) => {
  const mapper = new Mapper({
    map: mapSource.rules,
    mappedOnly,
    onProgress,
  });
  return await mapper.mapAsync(list);
};

export const mapUsingJS = async ({
  list,
  map,
  mappedOnly = false,
  onProgress = () => {},
}) => {
  const data = await JSMapper({
    handler: map.handler,
    data: list,
    onProgress,
  });
  if (mappedOnly) {
    return data;
  }
  return list.map((row, i) => ({ ...row, ...data[i] }));
};

export const mapUsingObjectMapper = async ({
  list,
  map,
  mappedOnly = false,
  onProgress = () => {},
}) => {
  const data = await ObjectMapper({
    rules: map.rules,
    data: list,
    onProgress,
  });
  if (mappedOnly) {
    return data;
  }
  return list.map((row, i) => ({ ...row, ...data[i] }));
};

export const mapUsingSimpleMapper = async ({
  list,
  map,
  mappedOnly = false,
  onProgress = () => {},
}) => {
  return await SimpleMapper({
    list,
    map: map.map,
    mappedOnly,
    onProgress,
  });
};

const mapList = async ({
  list,
  map,
  mappedOnly = false,
  onProgress = () => {},
}) => {
  map = ensureMapperObject(map);
  switch (map.type) {
    case 'map':
    case 'mapper':
      return await mapUsingMapper({ list, map, mappedOnly, onProgress });
    case 'js':
    case 'javascript':
      return await mapUsingJS({ list, map, mappedOnly, onProgress });
    case 'obj':
    case 'object':
      return await mapUsingObjectMapper({ list, map, mappedOnly, onProgress });
    case 'simple':
      return await mapUsingSimpleMapper({ list, map, mappedOnly, onProgress });
    default:
  }
  return list;
};

export default mapList;

//*
(async () => {
  /*
  const map = {
    type: 'mapper',
    rules: [
      { name: 'Zip', from: 'Zip Code' },
      { name: 'Counselor', from: 'counselor' }
    ]
  };
  //*/
  /*
  const map = {
    type: 'simple',
    map: [
      {
        counselor: 'counselor',
        //zip: 'zip, zipcode, zip code, Zip Code'
        zip: ['zip', 'zipcode', 'zip code', 'Zip Code']
      }
    ]
  };
  //*/
  /*
  const map = {
    type: 'js',
    handler({ data, item, index, count, ...options }) {
      const { counselor, 'Zip Code': zip } = item;
      return { counselor, zip };
    }
  };
  //*/
  /*
  const map = {
    type: 'object',
    rules: {
      counselor: {
        fields: ['counselor']
      },
      zip: {
        fields: ['zip', 'zipcode', 'Zip Code']
      }
    }
  };
  //*/
  /*
  const list = [
    {
      counselor: 'John Brown',
      Phone: '320-308-1663',
      Territory: 'Out of State',
      'Zip Code': ''
    },
    {
      counselor: 'Bill Brown',
      Phone: '320-308-1663',
      Territory: 'Central MN'
      //'Zip Code': '55301'
    },
    {
      counselor: 'Sally',
      Phone: '',
      Territory: 'Central MN',
      'Zip Code': '55302'
    },
    {
      counselor: 'John Brown',
      Phone: '',
      Territory: 'Central MN',
      'Zip Code': '55308'
    }
  ];

  const data = await mapList({ map, list });

  console.log('mapList.data', data);
  //*/
})();
//*/
