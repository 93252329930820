import request from 'data/request';
import { DEFAULT_EMMA_BLOCK_SIZE } from 'utils/const';

const getEmmaListResourceBlock = async ({
  accessToken,
  resource,
  uri,
  start,
  end,
  updateFunction,
}) => {
  updateFunction(`Fetching ${resource} ${start} to ${end}`);
  const result = await request({
    accessToken,
    url: `${uri}&start=${start}&end=${end}`,
  });
  return result.data;
};

const getEmmaListResource =
  ({ resource, accountId, mailingId, limit = DEFAULT_EMMA_BLOCK_SIZE }) =>
  async ({ accessToken, updateFunction }) => {
    const uri = `/api/emma/${resource}?accountId=${accountId}&id=${mailingId}&format=json`;
    const fetchBlock = async (blockStart) => {
      const result = await getEmmaListResourceBlock({
        accessToken,
        resource,
        uri,
        start: blockStart,
        end: blockStart + limit,
        updateFunction,
      });
      return result;
    };
    const fetchAll = async (offset = 0, results = []) => {
      const block = await fetchBlock(offset);
      if (Array.isArray(block) && block.length) {
        return await fetchAll(offset + limit, results.concat(block));
      }
      return results.length ? results : block;
    };
    return fetchAll();
  };

export default getEmmaListResource;
