import defaultedValue from 'utils/defaultedValue';

export const getRecordMembers = (rows = []) => {
  if (!Array.isArray(rows)) {
    return Array.from(new Set(Object.keys(rows)));
  }
  return rows.reduce(
    (cols, row) => Array.from(new Set([...Object.keys(row), ...cols])),
    []
  );
};

export const makeColumnHeaderText = (text) =>
  text.replace(/([a-z])([A-Z+])/g, (_, a, b) => `${a} ${b}`);

export const asColumn = (col) => {
  if (typeof col === 'string') {
    return {
      dataField: col,
      text: makeColumnHeaderText(col),
    };
  }
  const { key, header, dataField, text } = col;
  return {
    ...col,
    dataField: defaultedValue(dataField, key, header, text),
    text: defaultedValue(
      text,
      makeColumnHeaderText(defaultedValue(header, key, dataField))
    ),
  };
};

export const asHeaderKey = (col) => {
  const def = asColumn(col);
  const { dataField, text } = def;
  return {
    key: dataField,
    header: text,
    ...def,
  };
};

export const calculateColumns = (cols) => {
  const columns = cols.map(asColumn);
  if (columns.length === 0) {
    return [{ dataField: '', text: '' }];
  }
  return columns;
};

export const makeDisplayValue = (value, defaultValue = '') => {
  const type = typeof value;
  if (type === 'undefined') {
    return defaultValue;
  }
  return value;
};
