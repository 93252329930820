import DataFormPage from 'components/Pages/DataForm';
import {
  Group,
  Label,
  Control,
  File,
  Select,
  Option
} from 'components/Forms/Form';
import { CSV_FILE_EXTENSIONS } from 'utils/const';
import { loadCSVFile } from 'utils/file';
import downloadCSV from 'utils/downloadCSV';

const props = {
  caption: 'Fuzzy Matcher',
  description: 'Performs a fuzzy match from one list to another.',
  icon: 'nc-preferences-circle-rotate',
  path: '/account/:accountId/lists/fuzzymatch'
};

const handleSubmit = async (data, { setDebugData, setError, setStatus }) => {
  const {
    listfile,
    //clientdatafile,
    //trainingsetfile,
    //matchmode,
    filename,
    debug
  } = data;
  try {
    setStatus({ done: false, message: 'Loading lists' });
    const list = (await loadCSVFile(listfile)).data;
    //const clientdata = (await loadCSVFile(clientdatafile)).data;

    setError('');

    setStatus({ done: true });
    if (debug) {
      return setDebugData({ data: list });
    }
    return downloadCSV({ data: list, filename });
  } catch (e) {
    setError(e);
    setStatus({ done: true });
  }
};

const Component = ({ client, match }) => {
  return (
    <DataFormPage {...props} debug={false} onSubmit={handleSubmit}>
      <Group className="mb-3">
        <Label>Source List File</Label>
        <File name="listfile" accept={CSV_FILE_EXTENSIONS} />
      </Group>
      <Group className="mb-3">
        <Label>Client Data File</Label>
        <File name="clientdatafile" accept={CSV_FILE_EXTENSIONS} />
      </Group>
      <Group className="mb-3">
        <Label>Training Set</Label>
        <File name="trainingsetfile" accept={'.json'} />
      </Group>
      <Group className="mb-3">
        <Label>Match Mode</Label>
        <Select name="matchmode">
          <Option value="same">In both files (same)</Option>
          <Option value="unique">In one file only (unique)</Option>
        </Select>
      </Group>
      <Group>
        <Label>Download Filename</Label>
        <Control
          size="sm"
          type="text"
          name="filename"
          placeholder="download.csv"
        />
      </Group>
    </DataFormPage>
  );
};

const FuzzyMatcher = {
  ...props,
  Component
};

export default FuzzyMatcher;
