/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import {
  useLocation,
  useHistory,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import AdminNavbar from 'components/Navbars/AdminNavbar';
//import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
//import FixedPlugin from 'components/FixedPlugin/FixedPlugin.js';

import replaceUrlTokens from 'utils/replaceUrlTokens';
import routes, { defaultRoute } from 'routes';
import sidebarImage from 'assets/img/sidebar-6.jpg';

const NotFound = () => (
  <div>
    <h1>404 - Not Found!</h1>
  </div>
);

const reAccountId = /account\/(\d+)/;

const getAccount = (accounts, pathname) => {
  const accountId = (reAccountId.exec(pathname) || [])[1];
  if (!accountId) {
    return accounts[0];
  }
  const account = accounts
    .filter((account) => account.accountId === accountId)
    .shift();
  return account || accounts[0];
};

function Admin(params) {
  const { accounts, user, location: l } = params;
  const history = useHistory();
  const [
    image,
    //  , setImage
  ] = React.useState(sidebarImage);
  const [
    color,
    //, setColor
  ] = React.useState('black');
  const [
    hasImage,
    //, setHasImage
  ] = React.useState(true);
  const client = getAccount(accounts, l.pathname);
  const setClient = (newClient) => {
    if (newClient && client && newClient.accountId === client.accountId) {
      return;
    }
    if (newClient) {
      history.push(`/admin/account/${newClient.accountId}`);
    }
  };

  const location = useLocation();
  const mainPanel = React.useRef(null);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        const { Component } = prop;
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <Component {...props} client={client} />}
            key={key}
            exact={!!prop.exact}
          />
        );
      }
      return null;
    });
  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanel && mainPanel.current) {
      mainPanel.current.scrollTop = 0;
    }
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
      var element = document.getElementById('bodyClick');
      element.parentNode.removeChild(element);
    }
  }, [location]);
  return (
    <div className="wrapper">
      <Sidebar
        color={color}
        image={hasImage ? image : ''}
        routes={routes}
        defaultRoute={defaultRoute}
        client={client}
        accounts={accounts}
        user={user}
        onClientChange={(client) => setClient(client)}
      />
      <div className="main-panel" ref={mainPanel}>
        <AdminNavbar
          client={client}
          accounts={accounts}
          onClientChange={(client) => setClient(client)}
        />
        <div className="content">
          <Switch>
            {getRoutes(routes)}
            <Redirect
              from="/admin"
              to={replaceUrlTokens(
                `${defaultRoute.layout}${defaultRoute.path}`,
                client
              )}
            />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Admin;
