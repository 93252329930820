import isNumeric from 'utils/isNumeric';
import trueType from 'utils/trueType';
import isTheSame from 'utils/isTheSame';
import { FUNCTION_START } from 'utils/const';

const _tokenize = (source, parsers, defaultType = 'unknown') => {
  const tokens = [];
  while (source) {
    let left = source.length;
    let token;
    for (let key in parsers) {
      try {
        const match = parsers[key].exec(source);
        if (match && match.index < left) {
          token = {
            token: match[0],
            type: key,
            matches: match.slice(1),
          };
          left = match.index;
        }
      } catch (e) {
        console.error(key, parsers[key]);
        console.error(e);
        throw e;
      }
    }
    if (left) {
      tokens.push({
        token: source.substr(0, left),
        type: defaultType,
      });
    }
    if (token) {
      tokens.push(token);
    }
    source = source.substr(left + (token ? token.token.length : 0));
  }
  return tokens;
};

export class Script {
  constructor(source, { scope = {}, allowUndefined = false } = {}) {
    this.scope = scope;
    this.source = source;
    this.allowUndefined = allowUndefined;
    this.exec = this.compile(source);
  }

  tokenize(source) {
    const patterns = {
      symbol: /\${([^}]+)}/,
      escape: /\\\${([^}]+)}/,
    };
    const tokens = _tokenize(source, patterns, 'text');
    return tokens.reduce((tokens, token, index, list) => {
      if (index === 0) {
        if (token.type === 'symbol') {
          return [token];
        }
        return [
          {
            token: token.token,
            type: 'text',
          },
        ];
      }
      const prev = tokens[tokens.length - 1];
      if (['text', 'escape'].indexOf(token.type) > -1) {
        if (prev.type !== 'symbol') {
          prev.token = prev.token + token.token;
          return tokens;
        }
        return tokens.concat({
          token: token.token,
          type: 'text',
        });
      }
      return tokens.concat(token);
    }, []);
  }

  parse(tokens) {
    if (tokens.length > 1) {
      return {
        op: 'concat',
        left: { op: 'return', left: tokens[0] },
        right: this.parse(tokens.slice(1)),
      };
    }
    if (tokens.length === 1) {
      return {
        op: 'return',
        left: tokens[0],
      };
    }
    return {};
  }

  valueFrom(symbol, scope) {
    const patterns = {
      dot: /\./,
      groupStart: /\[/,
      groupEnd: /]/,
      //selector: /('(?:[^'\\]|\.)*'|"(?:[^"\\]|\.)*")/,
      string: /('(?:[^']|\.)*'|"(?:[^"]|\.)*")/,
      leftParen: /\(/,
      rightParen: /\)/,
      comma: /,/,
    };
    const source = symbol.matches[0];
    const path = _tokenize(source, patterns, 'selector');
    const stack = [scope];
    while (path.length && stack.length) {
      const part = path.shift();
      switch (part.type) {
        case 'dot':
          // ignore dots
          break;
        case 'leftParen':
          const f = stack.pop();
          if (typeof f !== 'function') {
            throw new Error(`Method or function does not exist.`);
          }
          stack.push(FUNCTION_START);
          stack.push(f);
          stack.push(scope);
          break;
        case 'comma':
          stack.push(scope);
          break;
        case 'rightParen':
          let fs = stack.length - 1;
          while (fs > 0 && !isTheSame(stack[fs], FUNCTION_START)) {
            fs--;
          }
          const items = stack.splice(fs).slice(1);
          const func = items.shift();
          stack.push(func(...items));
          break;
        case 'groupStart':
          stack.push(scope);
          break;
        case 'groupEnd':
          if (stack.length < 2) {
            throw new Error('Invalid group ending');
          }
          const indexer = stack.pop();
          const scopedIndexer = scope[indexer];
          const val = stack.pop();
          if (typeof scopedIndexer !== 'undefined') {
            stack.push(val[scopedIndexer]);
            break;
          }
          stack.push(val[indexer]);
          break;
        case 'selector':
          const selectFrom = stack.pop();
          const selector = (part.matches && part.matches.length
            ? part.matches[0]
            : part.token
          ).trim();
          if (selector === 'this') {
            stack.push(scope.this);
            break;
          }
          if (isTheSame(selectFrom, scope)) {
            if (isNumeric(selector)) {
              stack.push(+selector);
              break;
            }
          }
          if (this.allowUndefined && typeof selectFrom === 'undefined') {
            stack.push(selectFrom);
            break;
          }
          const selectedValue = (selectFrom || {})[selector];
          stack.push(selectedValue);
          break;
        case 'string':
          const stringToken = part.token.substr(1, part.token.length - 2);
          stack.pop();
          stack.push(stringToken);
          break;
        default:
          throw new Error('It broke');
      }
    }
    if (stack.length > 1) {
      throw new Error(
        'Too many items on stack.  Did you forget a group ending "]"?'
      );
    }
    return stack.pop();
  }

  eval(tree, scope) {
    const { op, left, right } = tree;
    switch (op) {
      case 'concat':
        return `${this.eval(left, scope)}${this.eval(right, scope)}`;
      case 'return':
        if (left.type === 'text') {
          return left.token;
        }
        return this.valueFrom(left, scope);
      default:
        throw new Error(`Unknown operation ${op}`);
    }
  }

  compile(source) {
    const tree = this.parse(this.tokenize(source));
    const f = (localScope) => {
      const scope = Object.assign({ this: localScope }, this.scope, localScope);
      return this.eval(tree, scope);
    };
    f.toString = () => source;
    return f;
  }
}

export class Reform {
  constructor(rules, options = {}) {
    this.options = options || {};
    this.compile(rules || {}, options);
  }

  _compileObject(obj, options) {
    const keys = Object.keys(obj);
    const exec = keys.map((key) => {
      const f = this.compile(obj[key], options);
      return (obj, scope) => {
        obj[key] = f(scope);
        return obj;
      };
    });
    return ((steps) => {
      return (scope) => {
        return steps.reduce((result, f) => f(result, scope), {});
      };
    })(exec);
  }

  _compileArray(rules, options) {
    const exec = rules.map((item) => this.compile(item, options));
    return (function (steps) {
      return (scope) => steps.map((f) => f(scope));
    })(exec);
  }

  _compile(rules, options) {
    const type = trueType(rules);
    if (type === 'array') {
      return this._compileArray(rules, options);
    }
    if (type === 'object') {
      return this._compileObject(rules, options);
    }
    return () => {
      return rules;
    };
  }

  compile(rules, opts = {}) {
    const type = typeof rules;
    const options = Object.assign({}, this.options, opts);
    if (type === 'string') {
      const s = new Script(rules, options);
      return (this.transformer = (scope) => {
        return s.exec(scope);
      });
    }
    return (this.transformer = this._compile(rules, options));
  }

  reform(scope) {
    return this.transformer(scope);
  }
}

export default Reform;
