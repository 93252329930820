import React from 'react';

import { Nav, Dropdown } from 'react-bootstrap';

const DEFAULT_CLIENT_TEXT = '[Select Client]';

const ClientSelect = ({
  client = { name: DEFAULT_CLIENT_TEXT },
  accounts,
  onChange,
}) => {
  const selectClient = (client) => onChange && onChange(client);
  const items = accounts.map((client) => (
    <Dropdown.Item
      key={client.accountId}
      href="#"
      onClick={(e) => {
        e.preventDefault();
        selectClient(client);
      }}
    >
      {client.name}
    </Dropdown.Item>
  ));
  return (
    <Dropdown as={Nav.Item}>
      <Dropdown.Toggle
        aria-expanded={false}
        aria-haspopup={true}
        as={Nav.Link}
        data-toggle="dropdown"
        id="navbarDropdownMenuLink"
        variant="default"
        className="m-0"
      >
        <span className="no-icon">{client.name}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
        {items}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ClientSelect;
