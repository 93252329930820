import BasicPage from 'components/Pages/Basic';
import Provider from 'data/Provider';
import Table from 'components/Tables/Table';
import LinkButton from 'components/Buttons/Link';
import DeleteButton from 'components/Buttons/Delete';
import defaultedValue from 'utils/defaultedValue';
import request from 'data/request';
import { useHistory } from 'react-router';
import forceArray from 'utils/forceArray';

const props = {
  caption: 'Users',
  description: 'Setup and manage users.',
  icon: 'nc-single-02',
  path: '/users',
  isAdmin: true,
};

const columns = ['username', 'groups', { dataField: 'actions', text: '' }];

const deleteUser = (user, history) => {
  const uri = `/api/users/${user._id}?format=json`;
  request({ method: 'delete', uri })
    .then(() => {
      history.push('/admin/users');
    })
    .catch((e) => console.error(e));
};

const View = ({ client, users, accounts }) => {
  const history = useHistory();
  const getGroupName = (group) => {
    if (group === 'systems/admin') {
      return 'Stewart Admin';
    }
    const [, id] = group.split('/');
    const account = accounts
      .filter(({ accountId }) => id === accountId)
      .shift() || { name: group };
    return account.name;
  };
  return (
    <BasicPage {...props}>
      <Table
        data={users
          .map((user) => {
            const { username, email, groups } = user;
            const un = defaultedValue(username, email);
            return {
              username: un,
              groups: forceArray(groups).map(getGroupName).join(', '),
              actions: (
                <>
                  <LinkButton to={`/admin/user/${user._id}`}>Edit</LinkButton>
                  <DeleteButton
                    title="Delete User"
                    onDelete={() => deleteUser(user, history)}
                  >
                    Are you sure you want to delete {un}?
                  </DeleteButton>
                </>
              ),
            };
          })
          .sort((a, b) => a.username.localeCompare(b.username))}
        columns={columns}
      />
      <LinkButton to="/admin/user/new">New User</LinkButton>
    </BasicPage>
  );
};

const Component = (...props) => (
  <Provider
    {...props}
    provide={{
      users: `/api/users?format=json`,
      accounts: `/api/accounts?format=json`,
    }}
    view={(props) => {
      return <View {...props} />;
    }}
  />
);

const Users = {
  ...props,
  Component,
};

export default Users;
