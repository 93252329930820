import React, { useState } from 'react';
import DataFormPage from 'components/Pages/DataForm';
import { Group, Label, Input, Control, Select } from 'components/Forms/Form';
import Button from 'components/Buttons/Button';
import MultipleFileUploader from 'components/Forms/MultipleFileUploader';
import { CSV_FILE_EXTENSIONS, DEBUG_SAMPLE_SIZE } from 'utils/const';
import { getFilesContents, loadCSV } from 'utils/file';
import forceArray from 'utils/forceArray';
import mapList from 'utils/mapList';
import reduce from 'async/reduce';
import downloadCSV from 'utils/downloadCSV';
import Error from 'components/Errors/Error';
import {
  loadCounselorFile,
  getCounselorFromStateZip,
  getLowestCounselor,
} from 'utils/mappers/counselors';
import { getField } from 'utils/fields';
import defaultMapper from 'utils/mappers/common';

const props = {
  caption: 'Merge Lists - incomplete',
  description:
    'Merges two or more lists and outputs in client specific or Stewart standard format.',
  icon: 'nc-bullet-list-67',
  path: '/account/:accountId/lists/merge',
};

const getListMapper = ({ mapper = defaultMapper }) => mapper;

const loadAll = async (files, trimTo, size) => {
  const sources = await getFilesContents(files);
  const lists = Array.from(await Promise.all(sources.map(loadCSV)));
  if (trimTo) {
    return lists.map(({ data, errors, meta }) => ({
      data: data.slice(0, size),
      errors,
      meta,
    }));
  }
  return lists;
};

const loadAndFlattenAll = async (files, trimTo, size) => {
  const lists = await loadAll(files, trimTo, size);
  const list = lists.flat(10);
  return list;
};

const handleSubmit =
  (client) =>
  async (formData, { setDebugData, setError, setStatus, setFilesSettings }) => {
    try {
      setError('');
      setStatus({ done: false, message: 'Loading lists' });
      const {
        listfiles: lfs,
        filenames: fns,
        filetypes: fts,
        filename = 'merged.csv',
        debug,
      } = formData;

      const listfiles = forceArray(lfs);
      const filenames = forceArray(fns).filter((s) => !!s);
      const filetypes = forceArray(fts);

      const csvs = await loadAndFlattenAll(listfiles, debug, DEBUG_SAMPLE_SIZE);

      const lists = listfiles.map(({ name }, index) => ({
        data: csvs[index],
        filename: name,
      }));

      const ordered = filenames.map((fn, index) => {
        const file = lists.find(({ filename }) => filename === fn);
        const { data, filename } = file;
        const fileType = filetypes[index];
        return {
          index,
          filename,
          ...data,
          type: fileType,
          mapper: getMapperForType(fileType),
        };
      });
      const composite = await reduce(ordered, [], async (composite, list) => {
        const map = getListMapper(list);
        const filename = list.filename;

        const onProgress = ({ index, count }) => {
          setStatus({
            done: false,
            message: `Processing ${index} of ${count} from ${filename}`,
          });
        };

        if (typeof map === 'function') {
          return await map({
            ...list,
            onProgress,
            composite,
          });
        }
        const chunk = await mapList({
          map,
          list: map.composite ? composite : Array.from(list.data),
          onProgress,
        });

        if (map.composite) {
          return chunk;
        }
        return composite.concat(chunk);
      });

      setStatus({ done: true });
      setFilesSettings([]);

      if (debug) {
        console.warn(
          `Debug enabled, files sampled to ${DEBUG_SAMPLE_SIZE} rows!`
        );
        console.log({ ordered, composite });
        return setDebugData({ data: composite });
      }

      //console.log({ ordered, composite });
      //*
      return downloadCSV({ data: composite, filename });
      //*/
    } catch (e) {
      setError(e);
      setStatus({ done: true });
      console.error(e);
    }
  };

/*
const defaultMapper = {
  type: 'js',
  handler({ data, item, index, count, ...options }) {
    return item;
  },

  //handler({ composite = [], data = [] }) => composite.concat(data)
};
const defaultMapper = {
  type: 'js',
  composite: true,
  handler({ data, item, index, count, ...options }) {
    return item;
  },
};
const defaultMapper = async ({ composite = [], data = [] }) =>
  composite.concat(data);
const defaultMapper = async ({ composite = [], data = [] }) =>
  composite.concat(data);
//*/

const fileTypes = [
  { type: 'cbss', name: 'CBSS', re: /cbss/i, mapper: defaultMapper },
  { type: 'sat', name: 'SAT', re: /sat/i, mapper: defaultMapper },
  {
    type: 'encoura',
    name: 'Encoura',
    re: /nrc ?act|act ?nrc|encoura/i,
    mapper: defaultMapper,
  },
  { type: 'act', name: 'ACT', re: /act/i, mapper: defaultMapper },
  { type: 'nrccua', name: 'NRCCUA', re: /nrc/i, mapper: defaultMapper },
  {
    type: 'counselor',
    name: 'Counselor',
    re: /counselor/i,
    mapper({ composite: list, data: counselorMap }) {
      const {
        lut = {},
        counts: counselorCounts = {},
        counselors = [],
      } = loadCounselorFile(counselorMap);

      const stateField = getField(list, /state/i);
      const zipField = getField(list, /zip|zip *code/i);

      const listWithCounselorFromStateZip = Array.from(list).map((row) => {
        const state = row[stateField];
        const zip = row[zipField];
        const counselor = getCounselorFromStateZip(lut, state, zip);
        if (counselor) {
          counselorCounts[counselor]++;
        }
        return { ...row, counselor: counselor };
      });
      const finalList = listWithCounselorFromStateZip.map((row) => {
        if (!row.counselor) {
          const counselor = getLowestCounselor({
            counselors,
            counselorCounts,
          });

          if (counselor) {
            counselorCounts[row.counselor]++;
            return { ...row, counselor: counselor };
          }
        }
        return row;
      });

      return finalList;
    },
  },
];

const getMapperForType = (forType) =>
  (fileTypes.find(({ type }) => forType === type) || {}).mapper;

const getFiletypeValue = (filename, filetype) => {
  if (filetype) {
    return filetype;
  }
  const { type = '' } = fileTypes.find(({ re }) => re.exec(filename)) || {};
  return type;
};

const FileEditorMap = ({ filename, filetype = '' }) => {
  const [value, setValue] = useState(filetype);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <Select name="filetypes" value={value} onChange={handleChange}>
      <option>RAW</option>
      {fileTypes.map(({ type, name }) => (
        <option value={type} key={type}>
          {name}
        </option>
      ))}
    </Select>
  );
};

const FilePropertyEditor = ({
  name,
  type,
  index,
  count,
  valid,
  moveTo = () => {},
}) =>
  !valid ? (
    <tr>
      <td colSpan="3">
        <Error>File "{name}" is invalid!</Error>
      </td>
    </tr>
  ) : (
    <tr>
      <td>
        <Input name="filenames" value={name} readOnly={true} />
      </td>
      <td>
        <FileEditorMap filetype={type} />
      </td>
      <td>
        <Button onClick={() => moveTo(index, 0)}>First</Button>
        <Button onClick={() => moveTo(index, index - 1)}>Up</Button>
        <Button onClick={() => moveTo(index, index + 1)}>Down</Button>
        <Button onClick={() => moveTo(index, count)}>Last</Button>
      </td>
    </tr>
  );

const reNotAlhpaNum = /[^a-z0-9_-]+/gi;
const formatForFilename = (str) => str.replace(reNotAlhpaNum, '');

const Component = ({ client, match }) => {
  const [fileSettings, setFilesSettings] = useState([]);

  const moveFileTo = (oldIndex, newIndex) => {
    newIndex = Math.min(fileSettings.length - 1, Math.max(0, newIndex));
    const newFileSettings = fileSettings.slice();
    newFileSettings.splice(newIndex, 0, ...newFileSettings.splice(oldIndex, 1));
    setFilesSettings(newFileSettings);
  };

  const onUploadedFilesChanged = (files) => {
    setFilesSettings(
      files
        .sort((a, b) => {
          // Send counselor files to the bottom
          if (/counselor/i.exec(a.name)) {
            return 1;
          }
          if (/counselor/i.exec(b.name)) {
            return -1;
          }
          return a.name.localeCompare(b.name, undefined, {
            sensitivity: 'accent',
          });
        })
        .map(({ name }) => ({
          name,
          valid: /\.csv$/i.exec(name),
          type: getFiletypeValue(name),
        }))
    );
  };

  const defaultFilename = `${formatForFilename(
    client.short || client.name
  )}_${new Date().toISOString().substr(0, 10)}_merged.csv`;
  return (
    <DataFormPage
      debug={true}
      {...props}
      onSubmit={handleSubmit(client)}
      setFilesSettings={setFilesSettings}
    >
      <Group className="mb-3">
        <Label>Source List Files</Label>
        <div>
          <MultipleFileUploader
            name="listfiles"
            accept={CSV_FILE_EXTENSIONS}
            uploadFilesChanged={onUploadedFilesChanged}
            multiple
          />
          <table>
            <tbody>
              {fileSettings.map((settings, index) => (
                <FilePropertyEditor
                  key={settings.name}
                  moveTo={moveFileTo}
                  index={index}
                  count={fileSettings.length}
                  {...settings}
                />
              ))}
            </tbody>
          </table>
        </div>
      </Group>
      <Group className="mb-3">
        <Label>Dedupe On (clear for no deduplication)</Label>
        <Input name="dedupeon" defaultValue="e-?mail" />
      </Group>
      <Group>
        <Label>Download Filename</Label>
        <Control
          size="sm"
          type="text"
          name="filename"
          defaultValue={defaultFilename}
          placeholder={defaultFilename}
        />
      </Group>
    </DataFormPage>
  );
};

const MergeList = {
  ...props,
  Component,
};

export default MergeList;
