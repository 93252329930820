import defaultedValue from 'utils/defaultedValue';

const configValue = (keyName, defaultValue) =>
  defaultedValue(process.env[keyName], defaultValue);

export const CSV_FILE_EXTENSIONS = configValue('CSV_FILE_EXTENSIONS', '.csv');
//export const CSV_FILE_EXTENSIONS = configValue('CSV_FILE_EXTENSIONS', '.xlsx,.xlsb,.xlsm,.xls,.xml,.csv,.txt,.ods,.fods,.uos,.sylk,.dif,.dbf,.prn,.qpw,.123,.wb*,.wq*');

export const YAML_FILE_EXTENSIONS = configValue(
  'YAML_FILE_EXTENSIONS',
  '.yaml,.yml'
);

export const FUNCTION_START = configValue(
  'FUNCTION_START',
  '@@--__FunctionStart__--@@'
);

export const DEFAULT_EMMA_BLOCK_SIZE = configValue(
  'DEFAULT_EMMA_BLOCK_SIZE',
  200
);

export const DEBUG_SAMPLE_SIZE = configValue('DEBUG_SAMPLE_SIZE', 10);
