import classNames from 'classnames';

export const makeHref = ({ href, to }) => href || to || '#';

export const isOffsiteLink = ({ url }) => {
  const tmp = document.createElement('a');
  tmp.href = url;
  const isOffsite = tmp.host !== window.location.host;
  return isOffsite;
};

export const offsiteProps = ({ url, className = '' }) => {
  const isOffsite = isOffsiteLink({ url });

  if (!isOffsite) {
    return { className };
  }

  return {
    target: '_blank',
    rel: 'noopener noreferrer',
    className: classNames(className, 'link-external'),
  };
};
