import DataFormPage from 'components/Pages/DataForm';
import { Group, Label, Control, File } from 'components/Forms/Form';
import MapEditor from 'components/Editors/Map';
import ColumnsEditor from 'components/Editors/Columns';
import { CSV_FILE_EXTENSIONS } from 'utils/const';
import { loadCSVFile } from 'utils/file';
import strToArray from 'utils/strToArray';
import mapList from 'utils/mapList';
import ensureColumns from 'utils/ensureColumns';
import downloadCSV from 'utils/downloadCSV';

const props = {
  caption: 'List Mapper',
  description: 'Maps the provided list from one field format to another.',
  icon: 'nc-map-big',
  path: '/account/:accountId/lists/map',
};

const handleSubmit = async (data, { setDebugData, setError, setStatus }) => {
  const {
    listfile,
    mapMap: map,
    mapRules: rules,
    mapHandler: handler,
    mapType: type,
    columns: columnsSource,
    filename,
    debug,
  } = data;
  try {
    setStatus({ done: false, message: 'Loading lists' });
    const list = (await loadCSVFile(listfile)).data;
    setError('');

    //console.log({ type, map, rules, handler });
    const data =
      map || rules || handler
        ? await mapList({
            map: { type, map, rules, handler },
            list,
            onProgress({ index, count }) {
              setStatus({
                done: false,
                message: `Processing ${index} of ${count}`,
              });
            },
          })
        : list;
    const columns = ensureColumns(strToArray(columnsSource), data);

    setStatus({ done: true });
    if (debug) {
      console.log({ data, columns });
      return setDebugData({ data, columns });
    }
    return downloadCSV({ data, columns, filename });
  } catch (e) {
    console.error(e);
    setError(e);
    setStatus({ done: true });
  }
};

const Component = ({ client, match }) => {
  return (
    <DataFormPage {...props} debug={true} onSubmit={handleSubmit}>
      <Group className="mb-3">
        <Label>Source List File</Label>
        <File name="listfile" accept={CSV_FILE_EXTENSIONS} />
      </Group>
      <Group className="mb-3">
        <Label>Map</Label>
        <MapEditor name="map" map={client.map} />
      </Group>
      <Group className="mb-3">
        <Label>Columns</Label>
        <ColumnsEditor name="columns" columns={client.columns} />
      </Group>
      <Group>
        <Label>Download Filename</Label>
        <Control
          size="sm"
          type="text"
          name="filename"
          placeholder="download.csv"
        />
      </Group>
    </DataFormPage>
  );
};

const ListMapper = {
  ...props,
  Component,
};

export default ListMapper;
