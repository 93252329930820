import { Container, Row, Col } from 'react-bootstrap';
import Card from 'components/Cards/Card';
import Provider from 'data/ProviderWithRetry';
import getEmmaResource from 'data/getEmmaResource';
import getEmmaListResource from 'data/getEmmaListResource';
import Icon from 'components/Icons/Icon';
import DownloadDatasetButton from 'components/Buttons/DownloadDataset';

import Table from 'components/Tables/Table';
import TimelineGraph from 'components/Graphs/Timeline';
import PieChart from 'components/Charts/Pie';
import RadarChart from 'components/Charts/Radar';

const isDate = (date) => date instanceof Date && !isNaN(date.valueOf());

const isDisplayableValue = (key, value) => {
  const type = typeof value;
  if (type === 'undefined') {
    return false;
  }
  if (Array.isArray(value)) {
    return false;
  }
  if (isDate(value)) {
    return true;
  }
  if (type === 'object') {
    return false;
  }
  return true;
};

const props = {
  description: 'Shows the mailings reports',
  path: '/account/:accountId/reports/mailing/:mailingId',
};

const MailingsView = ({
  response,
  opens,
  optouts,
  clicks,
  links,
  client,
  accountId,
  mailingId,
}) => {
  const { opened = 0, bounced = 0, optedOut: unsubscribed = 0 } = response;
  const responseOverview = Object.entries(response)
    .filter(([key, value]) => isDisplayableValue(key, value))
    .map(([key, value]) => ({
      key,
      value,
    }));

  const linksLu = links.reduce(
    (links, link) => ({ ...links, [link.id]: link }),
    {}
  );

  const clicksSummary = clicks.reduce((summary, { linkId }) => {
    const link = linksLu[linkId].name;
    const value = summary[link] || 0;
    return { ...summary, [link]: value + 1 };
  }, {});

  const topClicksSummary = Object.entries(clicksSummary)
    .map(([key, value]) => ({ key, value }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 5)
    .reduce(
      (clicks, { key, value }) => ({
        ...clicks,
        [key]: value,
      }),
      {}
    );

  const clicksSummaryRows = (() => {
    const seen = {};
    const stats = clicks.reduce((summary, { memberId, linkId }) => {
      const link = linkId;
      const value = summary[link] || {
        total: 0,
        unique: 0,
      };
      value.total += 1;
      if (!seen[`${memberId}_${linkId}`]) {
        value.unique += 1;
        seen[`${memberId}_${linkId}`] = true;
      }
      return { ...summary, [link]: value };
    }, {});

    return Object.entries(stats)
      .map(([linkId, { total, unique }]) => ({
        Total: total,
        Unique: unique,
        Name: linksLu[linkId].name,
      }))
      .sort((a, b) => b.Unique - a.Unique);
  })();

  const fn = `${response.name.replace(/[^a-z0-9]+/gi, '_')}.zip`.toLowerCase();

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card
            title={
              <>
                {response.name}{' '}
                <DownloadDatasetButton
                  filename={fn}
                  {...{
                    response,
                    opens,
                    optouts,
                    clicks,
                    links,
                    client,
                    accountId,
                    mailingId,
                  }}
                >
                  <Icon icon="nc-cloud-download-93" />
                </DownloadDatasetButton>
              </>
            }
            description={response.subject}
          />
        </Col>
        <Col sm="6">
          <Card title="Behavior">
            <TimelineGraph
              data={{
                Clicked: clicks,
                Opened: opens,
                Unsubscribed: optouts,
              }}
            />
          </Card>
        </Col>
        <Col sm="3">
          <Card title="Statistics">
            <PieChart
              data={{
                Opens: opened,
                Bounced: bounced,
                Unsubscribed: unsubscribed,
              }}
              legend
            />
          </Card>
        </Col>
        <Col sm="3">
          <Card title="Top 5 Links">
            <RadarChart data={topClicksSummary} />
          </Card>
        </Col>
        <Col md="6">
          <Card title="Click Summary">
            <Table data={clicksSummaryRows} />
          </Card>
        </Col>
        <Col md="6">
          <Card title="Mailing Statistics">
            <Table data={responseOverview} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const Component = ({ client, match }) => {
  const { params } = match;
  const { accountId, mailingId } = params;

  return (
    <Provider
      client={client}
      accountId={accountId}
      mailingId={mailingId}
      provide={{
        response: getEmmaResource({
          resource: 'response',
          accountId,
          mailingId,
        }),
        opens: getEmmaListResource({ resource: 'opens', accountId, mailingId }),
        optouts: getEmmaListResource({
          resource: 'optouts',
          accountId,
          mailingId,
        }),
        clicks: getEmmaListResource({
          resource: 'clicks',
          accountId,
          mailingId,
        }),
        links: getEmmaListResource({ resource: 'links', accountId, mailingId }),
      }}
      view={MailingsView}
    />
  );
};

const MailingsReport = {
  ...props,
  Component,
};

export default MailingsReport;
