import loadMapFromString from 'utils/loadMapFromString';

const getMapperRules = (source) => {
  const type = typeof source;
  if (type === 'string') {
    return loadMapFromString(source);
  }
  if (Array.isArray(source)) {
    return source;
  }
  throw new Error(`Unknown rules format for map got "${type}"`);
};

export default getMapperRules;
