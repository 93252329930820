import {
  getRecordMembers,
  calculateColumns,
  makeColumnHeaderText,
} from 'components/Tables/helpers';

const ensureColumns = (columns, data) => {
  if (Array.isArray(columns) && columns.filter((v) => !!v).length) {
    return calculateColumns(columns);
  }
  const fields = getRecordMembers(data);
  return fields.map((fn) => ({
    dataField: fn,
    text: makeColumnHeaderText(fn),
  }));
};

export default ensureColumns;
