import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import Card from 'components/Cards/Card';
import icons from 'components/Icons/icons';
import Icon from 'components/Icons/Icon';
import Link from 'components/Links/Link';

const props = {
  //caption: 'Icons',
  description: 'Lists all Icons available for use',
  icon: 'nc-atom',
  path: '/icons',
};

const Component = () => (
  <Container fluid>
    <Col md="12">
      <Card
        title="100 Awesome Nucleo Icons"
        description={
          <>
            <p>
              Handcrafted by our friends from{' '}
              <Link href="https://nucleoapp.com/?ref=1712">NucleoApp</Link>
            </p>
            <p>
              Go <Link href="/admin/home">Home</Link>
            </p>
          </>
        }
        className="all-icons"
      >
        <Row>
          {icons.map((icon) => (
            <Col
              key={icon}
              className="font-icon-list"
              lg="2"
              md="3"
              sm="4"
              xs="6"
            >
              <Card>
                <div className="font-icon-detail">
                  <Icon icon={icon} />
                  <p>{icon}</p>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Card>
    </Col>
  </Container>
);

const Icons = {
  ...props,
  Component,
};

export default Icons;
