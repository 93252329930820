import DownloadDatasetButton from 'components/Buttons/DownloadDataset';
import Provider from 'data/ProviderWithRetry';
import getEmmaResource from 'data/getEmmaResource';
import getEmmaListResource from 'data/getEmmaListResource';
import Icon from 'components/Icons/Icon';

const props = {
  description: 'Exportst all data for the provided mailings',
  path: '/account/:accountId/export/mailings/:mailingIds',
};

const dataToFetch = ({ accountId, mailingId }) => ({
  response: getEmmaResource({
    resource: 'response',
    accountId,
    mailingId,
  }),
  //opens: getEmmaListResource({ resource: 'opens', accountId, mailingId }),
  optouts: getEmmaListResource({
    resource: 'optouts',
    accountId,
    mailingId,
  }),
  clicks: getEmmaListResource({
    resource: 'clicks',
    accountId,
    mailingId,
  }),
  links: getEmmaListResource({ resource: 'links', accountId, mailingId }),
});

const Component = ({ client, match }) => {
  const { params } = match;
  const { accountId, mailingIds: mailingIdsString } = params;
  const mailingIds = mailingIdsString
    .split(',')
    .filter((s) => !!s)
    .map((s) => s.trim());
  return (
    <>
      Downloading from {client.name}
      <ul>
        {mailingIds.map((mailingId) => (
          <div key={mailingId}>
            <Provider
              provide={dataToFetch({ accountId, mailingId })}
              view={(params) => {
                const fn = `${params.response.name.replace(
                  /[^a-z0-9]+/gi,
                  '_'
                )}.zip`.toLowerCase();
                return (
                  <DownloadDatasetButton
                    filename={fn}
                    client={client}
                    {...params}
                  >
                    <Icon icon="nc-cloud-download-93" /> {fn}
                  </DownloadDatasetButton>
                );
              }}
            />
          </div>
        ))}
      </ul>
    </>
  );
};

const MailingsExport = {
  ...props,
  Component,
};

export default MailingsExport;
