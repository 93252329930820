import { useState } from 'react';
import { Switch } from 'components/Forms/Form';
import BasicPage from 'components/Pages/Basic';
import Form from 'components/Forms/Form';
import Button from 'components/Buttons/Button';
import Table from 'components/Tables/Table';
import Error from 'components/Errors/Error';

const DataFormPage = ({
  caption,
  title,
  description,
  icon,
  onSubmit,
  children,
  submitButton = true,
  submitCaption = 'Submit',
  debug: defaultToDebug = false,
  showDebug = true,
  ...additionalProps
}) => {
  const [debug, setDebug] = useState(defaultToDebug);
  const [debugData, setDebugData] = useState({});
  const [status, setStatus] = useState({ done: true });
  const [error, setError] = useState();
  const handleSubmit = (formData, additionalProps) =>
    onSubmit && onSubmit({ ...formData, debug }, additionalProps);
  if (status && !status.done) {
    return <span>{status.message}</span>;
  }
  return (
    <BasicPage {...{ caption, title, description, icon }}>
      <Error error={error} />
      <Form
        onSubmit={handleSubmit}
        {...{ setError, setDebugData, setStatus, ...additionalProps }}
      >
        {children}
        {submitButton ? (
          <Button variant="primary" type="submit">
            {submitCaption}
          </Button>
        ) : (
          <></>
        )}
      </Form>
      <div>
        {showDebug ? (
          <Switch
            inline
            name="debug"
            checked={debug}
            onChange={(evt) => setDebug(evt.target.checked)}
          >
            Debug
          </Switch>
        ) : (
          <></>
        )}
        <Error error={error} />
        {debug ? (
          <Table data={debugData.data} columns={debugData.columns} />
        ) : (
          <></>
        )}
      </div>
    </BasicPage>
  );
};

export default DataFormPage;
