import { Table as BootstrapTable, Form } from 'react-bootstrap';
import TableProvider from 'components/Tables/TableProvider';

const TableHeaders = ({
  columns,
  rows,
  selected,
  onSelectAll,
  onDeselectAll,
  allSelected,
}) => (
  <thead>
    <tr>
      <th>
        <Form.Check
          type="checkbox"
          checked={allSelected}
          onChange={() =>
            allSelected
              ? onDeselectAll && onDeselectAll()
              : onSelectAll && onSelectAll()
          }
        />
      </th>
      {columns.map(({ text, key }, i) => (
        <th key={`${key}_${i}`}>{text}</th>
      ))}
    </tr>
  </thead>
);

const DataColumn = ({ children, value, ...props }) => (
  <td {...props}>{value || children}</td>
);

const TableRow = ({ columns, row, selected, onSelect, onDeselect }) => (
  <tr
    onClick={() => {
      selected
        ? onDeselect && onDeselect(row.__id__)
        : onSelect && onSelect(row.__id__);
    }}
  >
    <td>
      <Form.Check
        type="checkbox"
        checked={selected}
        onChange={() => {
          selected
            ? onDeselect && onDeselect(row.__id__)
            : onSelect && onSelect(row.__id__);
        }}
      />
    </td>
    {columns.map(({ dataField, defaultValue, formatter }) => (
      <DataColumn
        key={`${row.__id__}${dataField}`}
        value={
          row[dataField] ||
          defaultValue ||
          (formatter && formatter(row[dataField], row))
        }
      />
    ))}
  </tr>
);

const TableView = ({
  columns,
  data,
  selected,
  onSelectRecord,
  onDeselectRecord,
  onSelectAll,
  onDeselectAll,
  allSelected,
}) => (
  <BootstrapTable striped bordered hover responsive size="sm">
    {
      <TableHeaders
        columns={columns}
        rows={data}
        selected={selected}
        onSelectAll={onSelectAll}
        onDeselectAll={onDeselectAll}
        allSelected={allSelected}
      />
    }
    <tbody>
      {data.map(({ __id__, ...row }) => (
        <TableRow
          key={__id__}
          columns={columns}
          row={{ __id__, ...row }}
          selected={selected.includes(__id__)}
          onSelect={onSelectRecord}
          onDeselect={onDeselectRecord}
        />
      ))}
    </tbody>
  </BootstrapTable>
);

const Table = ({ data, columns, onExport, filename = 'download.csv' }) => {
  if (!Array.isArray(data)) {
    data = [data].filter((item) => !!item);
  }
  if (data.length === 0) {
    return <div>No data to display</div>;
  }

  return (
    <TableProvider
      keyField="id"
      data={data}
      columns={columns}
      filename={filename}
      onExport={onExport}
    >
      {(props) => <TableView {...props} />}
    </TableProvider>
  );
};

export default Table;
