import DataFormPage from 'components/Pages/DataForm';
import { Group, Label, Control, File } from 'components/Forms/Form';
import { CSV_FILE_EXTENSIONS } from 'utils/const';
import { getFilesContents, loadCSV, loadCSVFile } from 'utils/file';
import downloadCSV from 'utils/downloadCSV';
import { getField } from 'utils/fields';
import { compareZips } from 'utils/zips';
import mapSeries from 'async/mapValuesSeries';

const fetchGeoZip = (georec, geoZipField, geoZipPlusFourField = 'unknown') => {
  if (geoZipPlusFourField !== 'unknown') {
    const zip = georec[geoZipField];
    const plusFour = georec[geoZipPlusFourField];
    return `${zip}-${plusFour}`;
  }
  return georec[geoZipField];
};

const mapLists = async ({ geos, lists, setStatus }) => {
  return new Promise((resolve) => {
    setStatus({ done: false, message: 'Merging lists' });
    const geoZipField = getField(geos[0], 'zip', 'location', 'zip');
    const geoZipPlusFourField = getField(
      geos[0],
      'four',
      'plusfour',
      'zipplusfour',
      'unknown'
    );
    const geolists = [].concat(...geos);
    const listlists = [].concat(...lists);

    const listZipField = getField(listlists[0], 'zip ?code', 'zip', 'zip');
    const listZipPlusFourField = getField(
      listlists[0],
      'four',
      'plusfour',
      'zipplusfour'
    );

    const geoRecordCount = geolists.length;

    //const recs = geolists
    //  .map((georec, index) => {
    mapSeries(
      geolists,
      (georec, index, next) => {
        const geoZip = fetchGeoZip(georec, geoZipField, geoZipPlusFourField);

        const listrecs = listlists.filter((listrec) => {
          const zip = listrec[listZipField];
          const zipplusfour = listrec[listZipPlusFourField];
          if (!zip) {
            return false;
          }
          if (!zipplusfour) {
            return false;
          }
          return compareZips({ zip, plusfour: zipplusfour }, geoZip);
        });

        if (index === 0 || index % 50 === 0) {
          setStatus({
            done: false,
            message: `Processing ${index} of ${geoRecordCount}.`,
          });
          return setImmediate(() =>
            next(
              null,
              listrecs.map((rec) => Object.assign({}, georec, rec))
            )
          );
        }

        return next(
          null,
          listrecs.map((rec) => Object.assign({}, georec, rec))
        );
      },
      (err, data) => {
        const recs = [].concat(
          ...Object.values(data).filter((l) => l && l.length)
        );
        return resolve(recs);
      }
    );
  });
};

const props = {
  caption: 'Geo Fence',
  description: 'Performs a Geo Fence match',
  icon: 'nc-globe-2',
  path: '/account/:accountId/reports/geofence',
};

const handleSubmit = async (
  { listfiles, geolistfile, filename, debug },
  { setDebugData, setError, setStatus }
) => {
  try {
    setStatus({ done: false });
    setError('');
    const listSources = await getFilesContents(listfiles);
    const geos = (await loadCSVFile(geolistfile)).data;
    const lists = Array.from(await Promise.all(listSources.map(loadCSV))).map(
      ({ data }) => data
    );

    mapLists({ geos, lists, setStatus }).then((data) => {
      setStatus({ done: true });

      if (debug) {
        return setDebugData({ data });
      }
      return downloadCSV({ data, filename });
    });
  } catch (e) {
    console.error(e);
    setError(e.toString() + e.stack);
  }
};

const Component = ({ client, match }) => (
  <DataFormPage debug={false} {...props} onSubmit={handleSubmit}>
    <Group className="mb-3">
      <Label>Source List Files</Label>
      <File name="listfiles" accept={CSV_FILE_EXTENSIONS} multiple />
    </Group>
    <Group className="mb-3">
      <Label>Geo List</Label>
      <File name="geolistfile" accept={CSV_FILE_EXTENSIONS} />
    </Group>
    <Group>
      <Label>Download Filename</Label>
      <Control
        size="sm"
        type="text"
        name="filename"
        placeholder="download.csv"
      />
    </Group>
  </DataFormPage>
);

const GeoFenceReport = {
  ...props,
  Component,
};

export default GeoFenceReport;
