import mapValuesSeries from 'async/mapValuesSeries';
import forceArray from 'utils/forceArray';
import valueFrom from 'utils/valueFrom';

export const getFieldlistFrom = (lu) => {
  if (Array.isArray(lu)) {
    return lu.map((s) => `${s}`.trim()).filter((s) => !!s);
  }
  return `${lu}`
    .split(',')
    .map((s) => `${s}`.trim())
    .filter((s) => !!s);
};

export const mapItem = ({ item, map, index, count }) => {
  return map.reduce((obj, omap) => {
    return Object.entries(omap).reduce((obj, [key, lu]) => {
      const fields = getFieldlistFrom(lu || '');
      const value = valueFrom(fields, item);
      return { ...obj, [key]: value };
    }, obj);
  }, {});
};

const SimpleMapper = async ({ list, map: mapSource, onProgress }) => {
  const map = forceArray(mapSource);

  const doProgress = ({ index, count, item }) =>
    onProgress && onProgress({ index, count, item });

  return new Promise((resolve, reject) => {
    if (!Array.isArray(list)) {
      return resolve(mapItem({ item: list, map, index: 0, count: 1 }));
    }
    const count = list.length;

    mapValuesSeries(
      list,
      (item, index, next) => {
        const waitForIt = index % 50 === 0;
        doProgress({ index, count, item });

        if (waitForIt) {
          return setImmediate(() =>
            next(null, mapItem({ item, map, index, count }))
          );
        }
        return next(null, mapItem({ item, map, index, count }));
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }

        const recs = [].concat(
          ...Object.values(data) //.filter((l) => l && l.length)
        );
        return resolve(recs);
      }
    );
  });
};

export default SimpleMapper;
