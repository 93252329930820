import DataFormPage from 'components/Pages/DataForm';
import { Group, Label, Control, File, Input } from 'components/Forms/Form';
import ColumnsEditor from 'components/Editors/Columns';
import { CSV_FILE_EXTENSIONS } from 'utils/const';
import { getFilesContents, loadCSV } from 'utils/file';
import downloadCSV from 'utils/downloadCSV';
import strToArray from 'utils/strToArray';
import ensureColumns from 'utils/ensureColumns';

const props = {
  caption: 'Combine Lists',
  description: 'Combines two or more lists and returns the composite list.',
  icon: 'nc-vector',
  path: '/account/:accountId/lists/combine',
};

const loadAll = async (files) => {
  const sources = await getFilesContents(files);
  const lists = Array.from(await Promise.all(sources.map(loadCSV))).map(
    ({ data }) => data
  );
  return lists;
};

const loadAndFlattenAll = async (files) => {
  const lists = await loadAll(files);
  const list = lists.flat(10);
  return list;
};

const dedupeList = (list, dedupeField) => {
  if (!dedupeField) {
    return list;
  }
  const reIsDedupeField = new RegExp(dedupeField, 'i');
  const found = {};
  const valueFrom = (record) => {
    const v = Object.entries(record).find(([key]) => reIsDedupeField.exec(key));
    if (v) {
      return v[1];
    }
    return false;
  };
  const deduped = list.filter((record) => {
    const value = valueFrom(record);
    if (!value) {
      return true;
    }
    if (found[value]) {
      return false;
    }
    found[value] = true;
    return true;
  });

  /*
  console.log(
    `Dedupe finished started with ${list.length} ended with ${deduped.length}`
  );//*/
  return deduped;
};

const handleSubmit = async (
  formData,
  { setDebugData, setError, setStatus }
) => {
  try {
    setError('');
    setStatus({ done: false, message: 'Loading lists' });
    const {
      listfiles,
      filename = 'combined.csv',
      debug,
      dedupeon,
      columns: columnsSource,
    } = formData;
    setStatus({ done: false, message: `Loading lists` });
    const list = await loadAndFlattenAll(listfiles);

    setStatus({ done: false, message: `Deduplicating master list` });
    const data = dedupeList(list, dedupeon);

    setStatus({ done: false, message: `Calculating columns` });
    const columns = ensureColumns(strToArray(columnsSource), data);

    setStatus({ done: true });
    if (debug) {
      return setDebugData({ data, columns });
    }
    downloadCSV({ data, filename: filename || 'download.csv' });
    //*/
  } catch (e) {
    setError(e);
    setStatus({ done: true });
  }
};

const Component = ({ client, match }) => (
  <DataFormPage debug={false} {...props} onSubmit={handleSubmit}>
    <Group className="mb-3">
      <Label>List Files</Label>
      <File name="listfiles" accept={CSV_FILE_EXTENSIONS} multiple />
    </Group>
    <Group className="mb-3">
      <Label>Columns</Label>
      <ColumnsEditor name="columns" columns={client.columns} />
    </Group>
    <Group className="mb-3">
      <Label>Dedupe On (clear for no deduplication)</Label>
      <Input name="dedupeon" defaultValue="e-?mail" />
    </Group>
    <Group>
      <Label>Download Filename</Label>
      <Control
        size="sm"
        type="text"
        name="filename"
        placeholder="combined.csv"
      />
    </Group>
  </DataFormPage>
);

const CombineLists = {
  ...props,
  Component,
};

export default CombineLists;
