import { InputGroup, FormControl } from 'react-bootstrap';
import Button from 'components/Buttons/Button';
import Icon from 'components/Icons/Icon';

const TableSearchBar = ({ onSearch, onExportClick }) => {
  let input;
  const handleSearchClick = () => onSearch(input.value);
  const handleKeyUp = (e) => {
    onSearch && onSearch(input.value);
  };
  const handleClearClick = () => {
    input.value = '';
    onSearch && onSearch(input.value);
  };
  const handleExportClick = () => {
    onExportClick && onExportClick();
  };
  return (
    <InputGroup>
      <FormControl
        ref={(n) => (input = n)}
        onKeyUp={handleKeyUp}
        placeholder="Filter"
        aria-label="Filter table data"
      />
      <Button onClick={handleSearchClick}>
        <Icon icon="nc-zoom-split" />
      </Button>
      <Button onClick={handleClearClick}>
        <Icon icon="nc-simple-remove" />
      </Button>
      <Button onClick={handleExportClick}>
        <Icon icon="nc-cloud-download-93" />
      </Button>
    </InputGroup>
  );
};

export default TableSearchBar;
