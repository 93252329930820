import { InputGroup, FormControl } from 'react-bootstrap';
import Button from 'components/Buttons/Button';
import Icon from 'components/Icons/Icon';

const ActionInputEdit = ({
  onButtonClick,
  onKeyUp,
  onKeyDown,
  icon,
  placeholder,
  hint,
}) => {
  let input;
  const handleViewClick = () => onButtonClick && onButtonClick(input.value);
  const handleKeyUp = (e) => {
    onKeyUp && onKeyUp(input.value, e);
  };
  const handleKeyDown = (e) => {
    onKeyDown && onKeyDown(input.value, e);
  };
  return (
    <InputGroup>
      <FormControl
        ref={(n) => (input = n)}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        placeholder={placeholder}
        aria-label={hint}
      />
      <Button onClick={handleViewClick}>
        <Icon icon={icon} />
      </Button>
    </InputGroup>
  );
};

export default ActionInputEdit;
