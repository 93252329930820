import {
  ResponsiveContainer,
  Tooltip,
  Legend,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
} from 'recharts';

import { binByTimestamps, colorPicker } from 'components/Charts/helpers';

const binAllTimestamps = (records) => {
  const keys = Object.keys(records);
  const allRecords = [].concat(
    ...Object.entries(records).reduce(
      (records, [type, values]) => [
        ...records,
        ...values.map(({ timestamp }) => ({ type, timestamp })),
      ],
      []
    )
  );
  if (allRecords.length === 0) {
    return allRecords;
  }

  return binByTimestamps('timestamp')(allRecords).map((records) => {
    return keys.reduce(
      (result, key) => ({
        ...result,
        [key]: records.filter(({ type }) => type === key).length,
      }),
      {
        x0: records.x0,
        x1: records.x1,
        key: new Date(records.x1).toISOString().substr(0, 10),
        total: records.length,
      }
    );
  });
};

const TimelineGraph = ({ data, height = 300 }) => {
  const samples = Object.keys(data);

  const allRecords = binAllTimestamps(data);

  const lines = samples.map((sample, index) => (
    <Line
      key={sample}
      type="monotone"
      dataKey={sample}
      stroke={colorPicker(index)}
    />
  ));
  return (
    <ResponsiveContainer width="100%" minHeight={height}>
      <LineChart
        width={500}
        height={height}
        data={allRecords}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={'key'} />
        <YAxis />
        <Tooltip />
        <Legend />
        {lines}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TimelineGraph;
