import { useState } from 'react';
import Modal from 'components/Modals/Modal';
import classNames from 'classnames';

import { Button as BootstrapButton } from 'react-bootstrap';

const DEFAULT_CHILDREN = 'Are you sure you want to delete?';

const DeleteButton = ({
  title = 'Delete',
  text,
  caption,
  children = DEFAULT_CHILDREN,
  className,
  onDelete,
  ...props
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleDeleteClick = (e) => {
    e && e.stopPropagation && e.stopPropagation();
    setShow(false);
    onDelete && onDelete();
  };
  const handleShow = (e) => {
    e && e.stopPropagation && e.stopPropagation();
    setShow(true);
  };

  return (
    <>
      <BootstrapButton
        size="sm"
        className={classNames('btn-fill', 'btn-danger', className)}
        onClick={handleShow}
        {...props}
      >
        {text || caption || 'Delete'}
      </BootstrapButton>
      <Modal
        visible={show}
        onClose={handleClose}
        title={title}
        buttons={{
          close: 'Close',
          delete: {
            caption: 'Delete',
            className: 'btn-danger',
            onClick: handleDeleteClick,
          },
        }}
      >
        {children}
      </Modal>
    </>
  );
};

export default DeleteButton;
