import {
  ResponsiveContainer,
  Tooltip,
  Legend,
  PieChart as REPieChart,
  Pie,
  Cell,
} from 'recharts';

import { getValue, colorPicker } from 'components/Charts/helpers';

const PieChart = ({ data, height = 300, legend = false }) => {
  const data01 = Object.entries(data).map(([name, value]) => ({
    name,
    value: getValue({ value }),
  }));
  return (
    <ResponsiveContainer width="100%" minHeight={height}>
      <REPieChart width={100} height={100}>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={data01}
          cx="50%"
          cy="50%"
          outerRadius={80}
          fill="#8884d8"
        >
          {data01.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colorPicker(index)} />
          ))}
        </Pie>
        <Tooltip />
        {legend ? <Legend /> : <></>}
      </REPieChart>
    </ResponsiveContainer>
  );
};

export default PieChart;
