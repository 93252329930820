import classNames from 'classnames';

import { Button as BootstrapButton } from 'react-bootstrap';

const Button = ({ children, className, ...props }) => (
  <BootstrapButton
    size="sm"
    className={classNames('btn-fill', className)}
    {...props}
  >
    {children}
  </BootstrapButton>
);

export default Button;
