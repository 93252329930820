import { useRef } from 'react';
import Button from 'components/Buttons/Button';
import { getFilesContents } from 'utils/file';

const ImportFileButton = ({
  children,
  onImport,
  multiple = false,
  accept,
  ...props
}) => {
  const fileInput = useRef();
  const handleClick = (e) => {
    e && e.preventDefault();
    fileInput.current.click();
  };
  const fileInputChanged = async (e) => {
    const { target = {} } = e;
    const { value, files } = target;
    if (value) {
      const filesArray = Array.from(files);
      const contents = await getFilesContents(filesArray);
      onImport &&
        onImport(
          multiple ? contents : contents[0],
          multiple ? filesArray : filesArray[0]
        );
    }
  };
  return (
    <>
      <input
        ref={fileInput}
        type="file"
        style={{ display: 'none' }}
        onChange={fileInputChanged}
        {...{ accept, multiple }}
      />
      <Button {...props} onClick={handleClick}>
        {children}
      </Button>
    </>
  );
};

export default ImportFileButton;
