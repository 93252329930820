import request from 'data/request';

const getEmmaResource =
  ({ resource, accountId, mailingId, updateFunction }) =>
  async ({ accessToken, updateFunction }) => {
    updateFunction(`Fetching ${resource}`);
    const url = `/api/emma/${resource}?accountId=${accountId}&id=${mailingId}&format=json`;
    const result = await request({ accessToken, url });
    return result.data;
  };

export default getEmmaResource;
